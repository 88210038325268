import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import ProtectedAxios from '../api/protectedAxios'
import { BsArrowDownShort } from 'react-icons/bs'
import Tags from '../components/Tags'
import { toast } from 'react-hot-toast'
import { UserContext } from '../context/UserProvider'
import Messages from '../components/Messages'
import ShareChat from '../components/ShareChat'
import { Helmet } from "react-helmet"
import logo from "../assets/logo.svg";

const SharedChatView = ({ isEnterpriseAdmin = true }) => {
    const [user] = useContext(UserContext)

    const { chat_id } = useParams()
    const [chatDetails, setChatDetails] = useState('')
    const [loading, setLoading] = useState(true)

    const [messages, setMessages] = useState([])
    const [refreshTags, setRefreshTags] = useState(false)

    const [showingChatHeader, setShowingChatHeader] = useState(false)

    const navigate = useNavigate()

    const [pageEnded, setPageEnded] = useState(false)


    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 300) {
                setShowingChatHeader(true)
            } else {
                setShowingChatHeader(false)
            }


            if (document.getElementById('scroll-down-button')) {
                if (document.body.scrollHeight - window.scrollY < 1200) {
                    document.getElementById('scroll-down-button').style.display = 'none'
                } else {
                    document.getElementById('scroll-down-button').style.display = 'block'
                }
            }
        })
    }, [])

    useEffect(() => {
        fetchChatDetails()
    }, [chat_id])

    const fetchChatDetails = () => {
        setLoading(true)
        setChatDetails('')

        ProtectedAxios.post('/users/getSharedChatDetails', { chat_id, user_id: user.user_id })
            .then(res => {
                setChatDetails(res.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    return (
        <>
            <div className='container chat-main-container py-5'>
                {chatDetails
                    &&
                    <Helmet>
                        <title>{chatDetails.chat_name} | SageCollab</title>
                        <meta name="robots" content="index" />
                        <meta name="description" content={chatDetails.chat_description} />
                        <meta name="author" content={chatDetails.name} />
                        <meta name="keywords" content={chatDetails.tags_string} />
                        <meta property="og:title" content={chatDetails.chat_name} />
                        <meta property="og:description" content={chatDetails.chat_description} />
                        <meta property="og:type" content="article" />
                        <meta property="og:site_name" content="SageCollab" />
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "DiscussionForumPosting",
                                "headline": chatDetails.chat_name,
                                "author": {
                                    "@type": "Person",
                                    "name": chatDetails.username,
                                    "url": `https://app.sagecollab.com/user/${chatDetails.username}`,
                                },
                                "articleBody": chatDetails.Message.map(msg => {
                                    let responseContent = msg.Response.length > 0 ? msg.Response[0].response_content : "";
                                    return `${msg.message_content} AI Response: ${responseContent}`;
                                }).join(" "),
                                "datePublished": chatDetails.created_at,
                                "interactionStatistic": {
                                    "@type": "InteractionCounter",
                                    "interactionType": { "@type": "CommentAction" },
                                    "userInteractionCount": chatDetails.Message.length,
                                },
                                "comment": chatDetails.chat_notes.map(note => ({
                                    "@type": "Comment",
                                    "text": note.note_content,
                                    "author": {
                                        "@type": "Person",
                                        "name": note.created_by_ref.username,
                                        "url": `https://app.sagecollab.com/user/${chatDetails.username}`,
                                    },
                                    "datePublished": note.created_at,
                                })),
                                "url": `https://app.sagecollab.com/chat/shared/${chatDetails.chat_id}`,
                            })}
                        </script>
                    </Helmet>
                }

                {loading
                    ?
                    <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                        <div className="spinner-border spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>

                    :
                    <>
                        {chatDetails === ''
                            ?
                            <div className='d-flex justify-content-center align-items-center'>
                                <p className='text-danger text-center'>chat not found</p>
                            </div>

                            :
                            <div className=''>
                                <div>
                                    <div className='shared-chat-band'>
                                        <p className=''>
                                            {chatDetails.created_by === user.user_id
                                                ?
                                                <>
                                                    You shared this chat. &nbsp;
                                                    <ShareChat className="d-inline-flex font-xxs text-decoration-underline cursor-pointer" showAsLink={true} title="click here to manage" chat_id={chatDetails.chat_id} chatDetails={chatDetails} onChatDetailsChange={data => setChatDetails(prev => { return { ...prev, ...data } })} />
                                                </>

                                                :
                                                <>
                                                    Viewing shared chat from {chatDetails.name}.
                                                </>
                                            }

                                        </p>
                                    </div>

                                    <div className='chat-header mt-3'>
                                        <div className='chat-name'>
                                            <div className='d-flex align-items-center gap-3'>
                                                <span title={chatDetails.chat_name ? chatDetails.chat_name : chatDetails.message_content.replace(/(\r\n|\r|\n)/g, " ")}>
                                                    {chatDetails.chat_name
                                                        ? <>{chatDetails.chat_name}</>
                                                        : <>{chatDetails.message_content.replace(/(\r\n|\r|\n)/g, " ").substring(0, 50)}{chatDetails.message_content.length > 50 && '...'}</>
                                                    }
                                                </span>
                                            </div>

                                            <div className='d-flex align-items-center gap-1'>

                                                {chatDetails.model_provider === "OPENAI"
                                                    ?
                                                    <div className='chat-select-item' title={`Using OpenAI model ${chatDetails.model}`}>
                                                        <div className='d-flex justify-content-center align-items-center gap-2'>
                                                            <img src="https://www.svgrepo.com/show/306500/openai.svg" style={{ width: '20px' }} alt="OpenAI" />
                                                            <p className='m-0 font-xs'>OpenAI</p>
                                                        </div>
                                                    </div>

                                                    : chatDetails.model_provider === "GEMINI"
                                                        ?
                                                        <div className='chat-select-item' title={`Using Gemini model ${chatDetails.model}`}>
                                                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                                                <img alt="Gemini" src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" style={{ width: '20px' }} />
                                                                <p className='m-0 font-xs'>Gemini</p>
                                                            </div>
                                                        </div>

                                                        : chatDetails.model_provider === "BEDROCK"
                                                            ?
                                                            <div className='chat-select-item' title={`Using Gemini model ${chatDetails.model}`}>
                                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                                    <img alt="Bedrock" src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44" className='rounded-circle' style={{ width: '25px' }} />
                                                                    <p className='m-0 font-xs'>Bedrock</p>
                                                                </div>
                                                            </div>

                                                            :
                                                            <div className='chat-select-item'>
                                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                                    <img alt="SageCollab" src={logo} style={{ width: '20px' }} />
                                                                    <p className='m-0 font-xs'>SageCollab</p>
                                                                </div>
                                                            </div>
                                                }

                                            </div>
                                        </div>


                                        <div className='tags'>
                                            {/* Setting enterpriseAdmin true so that even the owner can't edit tags here */}
                                            <Tags refreshTags={refreshTags} setRefreshTags={setRefreshTags} chat_id={chat_id} isEnterpriseAdmin={true} />
                                        </div>


                                    </div>

                                    {/* Setting enterpriseAdmin true so that even the owner can't add notes here */}
                                    <Messages chat_id={chat_id} chatDetails={chatDetails} messages={messages} setMessages={setMessages} pageEnded={pageEnded} setPageEnded={setPageEnded} viewingSharedChat={true} isEnterpriseAdmin={true} />

                                </div>

                                <div className='continue-chat-container'>
                                    <NavLink
                                        to={
                                            chatDetails.created_by === user.user_id
                                                ? `/chat/${chat_id}`

                                                :
                                                user.accessToken
                                                    ? `/chat/shared/continue/${chat_id}`
                                                    : `/login?redirectTo=/chat/shared/continue/${chat_id}`
                                        }
                                        className='button'
                                    >
                                        {chatDetails.created_by === user.user_id
                                            ? "Go to my chat"
                                            : "Continue this chat"
                                        }
                                    </NavLink>
                                </div>

                                <button type='button' id='scroll-down-button' className='edit-btn scroll-down-button' title='scroll down' onClick={() => window.scrollTo(0, document.body.scrollHeight)}>
                                    <BsArrowDownShort className='edit-icon copy-icon' />
                                </button>

                            </div>
                        }
                    </>
                }
            </div >
        </>
    )
}

export default SharedChatView