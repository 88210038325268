import toast from "react-hot-toast"
import ProtectedAxios from "../../api/protectedAxios"
import { useEffect, useRef, useState } from "react"
import { AiFillEdit } from "react-icons/ai"
import { MdArrowBack } from "react-icons/md"
import { GrFormClose } from "react-icons/gr"

const CompanySelector = ({ selectedTemplateData, setSelectedTemplate }) => {
    const [companySearchTerm, setCompanySearchTerm] = useState("")
    const [companyUrl, setCompanyUrl] = useState("")
    const [fetchingCompanies, setFetchingCompanies] = useState(true)
    const [companies, setCompanies] = useState([])
    const [addingNewCompany, setAddingNewCompany] = useState(false)
    const [showingCompanyDropdown, setShowingCompanyDropdown] = useState(false)
    const companyDropdownRef = useRef(null)
    const [editingCompany, setEditingCompany] = useState(false)
    const [editingCompanyData, setEditingCompanyData] = useState({
        company_id: undefined,
        company_name: "",
        company_url: "",
    })
    const [updatingCompany, setUpdatingCompany] = useState(false)
    const [companyNotSelected, setCompanyNotSelected] = useState(false);
    useClickOutside(companyDropdownRef)
    function useClickOutside(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowingCompanyDropdown(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useEffect(() => {
        setShowingCompanyDropdown(true)
        setCompanyNotSelected(false)
    }, [companySearchTerm])
    const handleCompanyChange = e => {
        setCompanySearchTerm(e.target.value)
        let searchedTerm = e.target.value
        setFetchingCompanies(true)
        if (searchedTerm.length > 0) {
            ProtectedAxios.post("/users/getCompanies", { searchedTerm })
                .then(res => {
                    setFetchingCompanies(false)
                    setCompanies(res.data)
                })
                .catch(err => {
                    setFetchingCompanies(false)
                    console.log(err)
                    toast.error(err.response.data.error)
                })
        }
    }
    const addNewCompany = () => {
        setAddingNewCompany(true)
        ProtectedAxios.post("/users/createNewCompany", { searchedTerm: companySearchTerm, companyUrl })
            .then(res => {
                if (res.data) {
                    selectCompany(res.data)
                    setCompanySearchTerm("")
                    setCompanyUrl("")
                    setAddingNewCompany(false)
                }
            })
            .catch(err => {
                console.log(err)
                setAddingNewCompany(false)
                toast.error(err.response.data.error)
            })
    }
    const selectCompany = (_company) => {
        setSelectedTemplate(prev => { return { ...prev, selectedCompany: _company } })
    }
    const removeCompany = () => {
        setSelectedTemplate(prev => { return { ...prev, selectedCompany: null } })
    }
    const editCompany = (_company) => {
        setEditingCompany(true)
        setShowingCompanyDropdown(false)

        setEditingCompanyData(
            {
                company_id: _company.company_id,
                company_name: _company.company_name,
                company_url: _company.company_url,
            }
        )
    }
    const updateCompany = () => {
        setUpdatingCompany(true)
        ProtectedAxios.post("/users/updateCompany", { company_id: editingCompanyData.company_id, company_name: editingCompanyData.company_name, company_url: editingCompanyData.company_url })
            .then(res => {
                if (res.data) {
                    setSelectedTemplate(prev => {
                        return {
                            ...prev,
                            selectedCompany: {
                                company_id: res.data.company_id,
                                company_name: res.data.company_name,
                                company_url: res.data.company_url,
                            }
                        }
                    })
                    setCompanySearchTerm("")
                    setEditingCompany(false)
                    setUpdatingCompany(false)
                }
            })
            .catch(err => {
                setUpdatingCompany(false)
                console.log(err)
                toast.error(err.response.data.error)
            })
    }

    return (
        <div>
            <div className='input-grp template-grp'>
                <label htmlFor="company-select" className={`${companyNotSelected ? "text-danger" : ""}`}>Select Company</label>
                <input type="text" id="company-select" className={`${companyNotSelected ? "border-danger" : ""}`} value={companySearchTerm} onChange={handleCompanyChange} />
                {companySearchTerm.length > 0 && showingCompanyDropdown
                    &&
                    <div ref={companyDropdownRef} className='list-dropdown md' style={{ minHeight: "auto", maxHeight: "30vh" }}>
                        {fetchingCompanies
                            ?
                            <div className='py-3 opacity-50'>
                                <div className="d-flex m-auto spinner-border spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>

                            :
                            <div className='list-container'>
                                {companies.length === 0
                                    ?
                                    <>
                                        <p className='pt-3 px-3'>
                                            <div>
                                                <p>No result found, Create a new company</p>
                                                <label htmlFor="company-name">Company Name</label>
                                                <input type="text" id="company-name" value={companySearchTerm} onChange={e => setCompanySearchTerm(e.target.value)} />

                                                <label htmlFor="company-url" className='mt-4'>Company URL</label>
                                                <input type='text' id="company-url" value={companyUrl} onChange={e => setCompanyUrl(e.target.value)} className='mb-4' placeholder='company url' />

                                                <button type="submit" className='button button-ghost' onClick={() => addNewCompany()} disabled={addingNewCompany}>
                                                    {addingNewCompany
                                                        ?
                                                        <>
                                                            Create
                                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>
                                                        </>

                                                        : "Create"
                                                    }
                                                </button>
                                            </div>
                                        </p>
                                    </>

                                    :
                                    <>
                                        {companies.map((company, i) => {
                                            return (
                                                <div className='dropdown-company-element' key={i}>
                                                    <div className='company-detail' onClick={() => {
                                                        selectCompany(company)
                                                        setCompanySearchTerm("")
                                                    }
                                                    }>
                                                        <div>
                                                            {company.company_name}
                                                            <br />
                                                            <span className='subtitle'>{company.company_url.substring(0, 40)}{company.company_url.length > 40 && "..."}</span>
                                                        </div>
                                                    </div>
                                                    <div className='button-container'>
                                                        <button title="edit" className='edit-btn' onClick={() => editCompany(company)}><AiFillEdit className='edit-icon' style={{ fontSize: 'var(--fs-2)' }} /></button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                        }
                    </div>
                }
                {editingCompany
                    &&
                    <div className='template-dropdown'>
                        <div className='template-container'>
                            <p className='pt-3 px-3'>
                                <div>
                                    <p className='d-flex align-items-center'>
                                        <button type='button' title="edit" className='edit-btn' onClick={() => { setEditingCompany(false) }}><MdArrowBack className='edit-icon' style={{ fontSize: 'var(--fs-2)' }} /></button>
                                        Edit Company
                                    </p>
                                    <label htmlFor="company-name">Company Name</label>
                                    <input type="text" id="company-name" value={editingCompanyData.company_name} onChange={e => setEditingCompanyData(prev => { return { ...prev, company_name: e.target.value } })} />

                                    <label htmlFor="company-url" className='mt-4'>Company URL</label>
                                    <input type='text' id="company-url" value={editingCompanyData.company_url} onChange={e => setEditingCompanyData(prev => { return { ...prev, company_url: e.target.value } })} className='mb-4' placeholder='company url' />

                                    <button type="submit" className='button button-ghost' onClick={() => updateCompany()} disabled={updatingCompany}>
                                        {updatingCompany
                                            ?
                                            <>
                                                Update
                                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                    <span className="sr-only"></span>
                                                </div>
                                            </>

                                            : "Update"
                                        }
                                    </button>
                                </div>
                            </p>
                        </div>
                    </div>
                }
            </div>
            <div className='selected-tag-container'>
                {selectedTemplateData.selectedCompany !== null && selectedTemplateData.selectedCompany?.company_id
                    ?
                    <span className={`tag tag-color-${0 % 4 + 1} editing`}>{selectedTemplateData.selectedCompany.company_name} <button type='button' title='remove company' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { removeCompany() }}><GrFormClose className={`edit-icon`} /></button></span>

                    :
                    <span className={`subtitle ${companyNotSelected ? "text-danger" : ""}`} style={{ marginTop: "-0.7rem" }}>no company selected</span>
                }
            </div>
        </div>
    );
}

export default CompanySelector;