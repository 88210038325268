import { Dialog } from '../../ui/Dialog';

const ChangeTemplateTypeConfirmationDialog = ({
    dialogState,
    setDialogState,
    functionToExecuteOnConfirmation
}) => {

    return (
        <>
            <Dialog
                open={dialogState}
                onOpenChange={setDialogState}
                hideTrigger
                title="Are you sure?"
                titleClass="font-xs"
                body={
                    <div className='h-100 overflow-scroll'>
                        <p>You will loose you current changes if you change template type.</p>
                        <div className='d-flex justify-content-end'>
                            <button className='button button-danger' onClick={functionToExecuteOnConfirmation}>Yes, change type</button>
                        </div>
                    </div>
                }
                hideFooter
            />
        </>
    )
}

export default ChangeTemplateTypeConfirmationDialog;