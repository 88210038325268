import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserProvider';
import ProtectedAxios from '../api/protectedAxios'
import { useLocation, useNavigate } from 'react-router-dom';
import AddOpenaiAPIkeyModal from './AddOpenaiAPIkeyModal';
import { toast } from 'react-hot-toast';
import Template from './Template';
import { IoSend } from 'react-icons/io5';
import AdModal from './AdModal';
import AddGeminiAPIkeyModal from './AddGeminiAPIkeyModal';
import { GrClose } from 'react-icons/gr';
import logo from '../assets/logo.svg'
import MaxAiInteractionsReachedModal from './modals/MaxAiInteractionsReachedModal';
import AddBedrockAPIkeyModal from './AddBedrockAPIkeyModal';
import { decryptAndGetLocalStorageItem } from '../utils/helper';
import useSubscriptionDetails from '../hooks/useSubscriptionDetails';


const NewChat = ({ prompt, setPrompt, templateId, setTemplateId, updateTokens }) => {
    const [user] = useContext(UserContext);
    const [subscriptionDetail] = useSubscriptionDetails(user.stripe_customer_id);
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isUsingSelfAPI, setIsUsingSelfAPI] = useState(true);
    const [selectedModelProvider, setSelectedModelProvider] = useState("DEFAULT");
    const [loading, setLoading] = useState(false);

    const [showOpenaiApiKeyModal, setShowOpenaiApiKeyModal] = useState(false);
    const [showGeminiApiKeyModal, setShowGeminiApiKeyModal] = useState(false);
    const [showBedrockApiKeyModal, setShowBedrockApiKeyModal] = useState(false);
    const [showMaxInteractionsReachedModal, setShowMaxInteractionsReachedModal] = useState(false);
    const [showAdModal, setShowAdModal] = useState(false);


    const handleSubmit = async (e, _key, _prompt, _templateId, _addTemplateCreatorAsCollaborator) => {
        if (!user?.user_id) {
            const currentTemplateData = JSON.stringify(prompt);
            localStorage.setItem(`startChatTemplateData-${_templateId}`, currentTemplateData);

            const searchParams = new URLSearchParams(location.search);
            navigate(`/login?redirectTo=${location.pathname}?${searchParams.toString()}`);

            toast("Login/signup to continue");

            return;
        }

        let apiKey =
            _key
                ? _key
                : selectedModelProvider === "OPENAI" ? user.openai_api_key
                    : selectedModelProvider === "GEMINI" ? user.gemini_api_key
                        : selectedModelProvider === "BEDROCK" && user.bedrock_access_key


        if (e) {
            e.preventDefault()
        }

        if (apiKey || user.role_id === 5 || !isUsingSelfAPI || selectedModelProvider === "DEFAULT") {
            if (process.env.REACT_APP_SHOW_AD === "true" && subscriptionDetail.price_id === process.env.REACT_APP_PRICE_A_ID) {
                setShowAdModal(true)
            }

            setLoading(true)
            toast.loading('Generating response...', { id: 'new-chat', duration: Infinity });

            const startTime = performance.now()

            let updatedPrompt = prompt
            if (_prompt) {
                updatedPrompt = _prompt
            }

            let updated_template_id = selectedTemplate?.template_id
            if (_templateId) {
                updated_template_id = _templateId
            }

            ProtectedAxios.post('/users/newChat', { prompt: updatedPrompt, user_id: user.user_id, customer_id: user.stripe_customer_id, role_id: user.role_id, is_using_self_api: isUsingSelfAPI, model_provider: selectedModelProvider, template_id: updated_template_id, addTemplateCreatorAsCollaborator: _addTemplateCreatorAsCollaborator })
                .then(res => {
                    if (res.data) {
                        const endTime = performance.now()
                        let timeTaken = endTime - startTime
                        let waitingTime = 15000 - timeTaken

                        if (subscriptionDetail.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                            waitingTime = 0
                        }

                        setTimeout(() => {
                            setShowAdModal(false)
                            setLoading(false)
                            try {
                                updateTokens()
                            }
                            catch (err) { console.log(err) }

                            setPrompt("")
                            navigate(`/chat/${res.data.chat_id}`)
                            toast.success('Response generated!', { id: 'new-chat', duration: 3000 });

                            // clear saved template structure from localstorage
                            localStorage.removeItem(`savedTemplateStructure-${updated_template_id}`);
                        }, waitingTime)
                    }
                })
                .catch(err => {
                    console.log(err);

                    const endTime = performance.now()
                    let timeTaken = endTime - startTime
                    let waitingTime = process.env.REACT_APP_AD_DURATION - timeTaken
                    if (subscriptionDetail.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                        waitingTime = 0
                    }

                    setTimeout(() => {
                        setShowAdModal(false)
                        setTemplateId(null)
                        setSelectedTemplate(null)
                        if (err.response.status === 500) {
                            toast.error(err.response.data.error, { duration: Infinity, id: 'new-chat' })
                            setLoading(false)
                        }
                        else if (err.response.status === 429) {     //max ai interactions reached
                            setShowMaxInteractionsReachedModal(true)
                            toast.dismiss("new-chat")
                            setLoading(false)
                        }
                        else if (err.response.status === 403) {     //plan limits reached
                            toast.error(err.response.data.error, { id: 'new-chat' })
                            navigate('/upgrade')
                            setLoading(false)
                        }
                        else if (err.response.status === 401) {     //not enough tokens
                            toast.error(err.response.data.error, { id: 'new-chat' })
                            setLoading(false)
                        }
                        else {
                            console.log(err);
                            setLoading(false)
                            toast.dismiss("new-chat")
                        }
                        setTemplateId(null);
                        setPrompt("");
                    }, waitingTime)
                })
        }
        else {
            if (selectedModelProvider === "OPENAI") {
                setShowOpenaiApiKeyModal(true)
            }
            else if (selectedModelProvider === "GEMINI") {
                setShowGeminiApiKeyModal(true)
            }
            else if (selectedModelProvider === "BEDROCK") {
                setShowBedrockApiKeyModal(true)
            }
        }


    }
    const handleTextareaChange = (e, stateSetterFunction) => {
        const textarea = document.getElementById("prompt-input");
        let screenWidth = window.screen.width
        const lineCount = textarea.value.split('\n').length;

        let size = parseInt(getComputedStyle(document.body).getPropertyValue('--size-xs').slice(0, -2))

        // Calculate the number of rows needed based on line count and content height
        const rowsNeeded = Math.max(lineCount, textarea.scrollHeight / size / 2);

        if (textarea.value.length === 0) {
            if (screenWidth <= 786) {
                textarea.rows = 2;
            }
            else {
                textarea.rows = 1;
            }
        } else {
            textarea.rows = rowsNeeded;
        }

        if (stateSetterFunction) {
            stateSetterFunction(textarea.value)
        }
    }


    useEffect(() => {
        // For mobile devices make the input box height larger
        const textarea = document.getElementById("prompt-input");
        let screenWidth = window.screen.width
        if (screenWidth <= 786) {
            textarea.rows = 2;
        }

        // Check and use saved prompt if available
        const savedPrompt = decryptAndGetLocalStorageItem("start_chat_saved_prompt");
        if (savedPrompt) {
            setPrompt(savedPrompt);
            localStorage.removeItem("start_chat_saved_prompt");
        }
    }, []);
    useEffect(() => {
        if (selectedModelProvider === "GEMINI") {
            setIsUsingSelfAPI(true)
        }
    }, [selectedModelProvider]);


    return (
        <>
            <div className='w-100'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex chat-select'>
                        <div
                            onClick={() => setSelectedModelProvider("DEFAULT")}
                            className={`chat-select-item ${selectedModelProvider === "DEFAULT" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src={logo} style={{ width: '20px' }} alt="sagecollab" />
                                <p className='m-0 font-xs'>SageCollab</p>
                            </div>
                        </div>

                        <div
                            onClick={() => setSelectedModelProvider("OPENAI")}
                            className={`chat-select-item ${selectedModelProvider === "OPENAI" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src="https://www.svgrepo.com/show/306500/openai.svg" style={{ width: '20px' }} alt="openai" />
                                <p className='m-0 font-xs'>OpenAI</p>
                            </div>
                        </div>

                        <div
                            onClick={() => setSelectedModelProvider("GEMINI")}
                            className={`chat-select-item ${selectedModelProvider === "GEMINI" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" style={{ width: '20px' }} alt="gemini" />
                                <p className='m-0 font-xs'>Gemini</p>
                            </div>
                        </div>

                        <div
                            onClick={() => setSelectedModelProvider("BEDROCK")}
                            className={`chat-select-item ${selectedModelProvider === "BEDROCK" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44" className='rounded-circle' style={{ width: '25px' }} alt="bedrock" />
                                <p className='m-0 font-xs'>Bedrock</p>
                            </div>
                        </div>
                    </div>
                </div>

                <form id='add-message-form' className='add-message-container w-100' onSubmit={handleSubmit}>
                    <div className='textarea-container template-grp'>
                        <button type='submit' id='copy-promptReply-response-button' disabled={prompt.length === 0 || loading} className='send-message-button' title='start chat'>
                            {loading
                                ? <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                                : <IoSend className='edit-icon copy-icon' />
                            }
                        </button>
                        <textarea
                            placeholder="start typing to chat or type '/' to select a template"
                            rows={1}
                            required
                            id="prompt-input"
                            value={selectedTemplate?.type === "CHAT_STARTER_PRIVATE" || selectedTemplate?.type === "CHAT_STARTER_OPEN" ? '' : prompt}
                            disabled={loading}
                            onChange={e => handleTextareaChange(e, setPrompt)}
                        />
                        <Template placement='top' textInput={prompt} setPrompt={setPrompt} template_id={templateId} setTemplateId={setTemplateId} setTemplate={setSelectedTemplate} handleTextareaChange={handleTextareaChange} submit={handleSubmit} />
                    </div>
                </form>

                <div className='d-flex justify-content-between align-items-center w-100' style={{ minHeight: "3rem" }}>
                    <div />

                    {selectedTemplate
                        &&
                        <div className='selected-template' title={selectedTemplate?.description}>
                            <div className='d-flex align-items-center justify-content-between gap-3'>
                                <span className='font-xxs'>Selected Template</span>
                                <button onClick={() => { setSelectedTemplate(null); setPrompt("") }} className='bg-transparent border-0' disabled={loading}>
                                    <GrClose className='remove-icon' />
                                </button>
                            </div>
                            <div>
                                <p className='m-0 text-secondary font-xxs'>
                                    {selectedTemplate.name.substring(0, 20)}{selectedTemplate.name.length > 20 && "..."}
                                </p>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <AddOpenaiAPIkeyModal
                show={showOpenaiApiKeyModal}
                onHide={() => setShowOpenaiApiKeyModal(false)}
                title='Add your OpenAI API key to continue chat or use SageCollab tokens'
                handleSubmit={handleSubmit}
            />

            <AddGeminiAPIkeyModal
                show={showGeminiApiKeyModal}
                onHide={() => setShowGeminiApiKeyModal(false)}
                title='Add your Gemini API key to continue chat'
                handleSubmit={handleSubmit}
            />

            <AddBedrockAPIkeyModal
                show={showBedrockApiKeyModal}
                onHide={() => setShowBedrockApiKeyModal(false)}
                title='Add your Bedrock API key to continue chat'
                handleSubmit={handleSubmit}
            />

            <MaxAiInteractionsReachedModal
                show={showMaxInteractionsReachedModal}
                onHide={() => setShowMaxInteractionsReachedModal(false)}
            />

            <AdModal
                show={showAdModal}
                onHide={() => setShowAdModal(false)}
            />
        </>
    )
}

export default NewChat