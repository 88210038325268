import React, { useRef, useState } from 'react';
import { IoCheckmark, IoCopy } from "react-icons/io5";

const CodeBlock = ({ lang, codeChildren }) => {
  const codeRef = useRef(null);

  return (
    <div className="code-block">
      <CodeBar lang={lang} codeRef={codeRef} />
      <div className="code-content">
        <code ref={codeRef} className={`d-block hljs language-${lang}`}>
          {codeChildren}
        </code>
      </div>
    </div>
  );
};

const CodeBar = React.memo(({ lang, codeRef }) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className="code-block-header">
      <span className='code-block-lang'>{lang}</span>
      <button
        className="button-copy-code"
        aria-label="copy codeblock"
        onClick={async () => {
          const codeString = codeRef.current?.textContent;
          if (codeString) {
            navigator.clipboard.writeText(codeString).then(() => {
              setIsCopied(true);
              setTimeout(() => setIsCopied(false), 3000);
            });
          }
        }}
      >
        {isCopied ? (
          <>
            <IoCheckmark />
            Copied!
          </>
        ) : (
          <>
            <IoCopy />
            Copy
          </>
        )}
      </button>
    </div>
  );
});

export default CodeBlock;
