import { useState } from 'react';
import { Dialog } from '../../ui/Dialog';
import { renderSelectedTemplateDetailsForm, renderSelectedTemplateElements, renderSelectedTemplatePreview, renderTemplateElements, verifyTemplateBuilderStep } from '../utils';
import ProtectedAxios from '../../../api/protectedAxios';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { RiGlobalLine, RiLockLine } from 'react-icons/ri';
import { HiOutlineTemplate } from 'react-icons/hi';
import ChangeTemplateTypeConfirmationDialog from './ChangeTemplateTypeConfirmationDialog';

const TemplateBuilderDialog = ({
    dialogState,
    setDialogState,
    setTemplates,
    user,
    mode,
    subscriptionDetail,
}) => {
    const [templateData, setTemplateData] = useState({
        name: '',
        description: '',
        prompt: '',
        selectedCategories: [],
        selectedCompany: null,
        showing_additional_options: false,
        isSharingWithChildAccounts: true,
        isPublic: false,
        isOfficial: true,
        notify_creator_on_use: false,
        type: '',
        typeText: ''
    });
    const [newTemplateStructure, setNewTemplateStructure] = useState([]);
    const [savingTemplate, setSavingTemplate] = useState(false);
    const [templateBuilderError, setTemplateBuilderError] = useState("")
    const [verifyingTemplateStep, setVerifyingTemplateStep] = useState(false)
    const [templateBuilderStep, setTemplateBuilderStep] = useState(0);
    const [changeTemplateTypeConfirmationDialogState, setChangeTemplateTypeConfirmationDialogState] = useState(false);

    const handleNewTemplateItemSort = (e) => {
        const dragItemIndex = e.dragData.index

        const targetElementId = e.target.querySelector(".template-element").getAttribute("id")
        const dragOverItemIndex = parseInt(targetElementId.split("-")[targetElementId.split("-").length - 1])

        // duplicate structure
        let new_structure = [...newTemplateStructure]

        //remove and save the dragged item content
        const draggedItemContent = new_structure.splice(dragItemIndex, 1)[0]

        //switch the position
        new_structure.splice(dragOverItemIndex, 0, draggedItemContent)

        //update the actual state
        setNewTemplateStructure(new_structure)

        e.target.scrollIntoView()
    }

    const addTemplate = async (e) => {
        e.preventDefault()

        if ((user.role_id === 1 || templateData.isOfficial) && (user.is_official_template_creator || user.role_id === 1) && templateData.selectedCompany === null) {
            toast.error("Official templates must have a company selected");
            return;
        }

        if (templateData.name.length > 200) {
            toast.error("name must be below 200 characters")
            return
        }
        if (templateData.description.length > 500) {
            toast.error("description must be below 500 characters")
            return
        }

        setSavingTemplate(true)
        let prompt = JSON.stringify(newTemplateStructure)
        let isEnterpriseAdmin =
            (user?.plan_id ? user.plan_id === process.env.REACT_APP_PRICE_C_ID : subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID)
                ? true
                : false


        // using formData since want to add files too
        const formData = new FormData()
        formData.append('user_id', user.user_id)
        formData.append('selected_user_id', user?.user_id)
        formData.append('selected_stripe_customer_id', user?.stripe_customer_id)
        formData.append('selected_role_id', user?.role_id)
        formData.append('isEnterpriseAdmin', isEnterpriseAdmin)
        formData.append('isSharingWithChildAccounts', templateData.isSharingWithChildAccounts)
        formData.append('prompt', prompt)
        formData.append('name', templateData.name)
        formData.append('description', templateData.description)
        formData.append('showingAdditionalOptions', templateData.showing_additional_options)
        formData.append('selectedCategories', JSON.stringify(templateData.selectedCategories))
        formData.append('selectedCompany', JSON.stringify(templateData.selectedCompany))
        formData.append('isPublic', templateData.isPublic)
        formData.append('notify_creator_on_use', templateData.notify_creator_on_use)
        formData.append('type', templateData.type)

        newTemplateStructure.forEach((element) => {
            if ((element.type === 'pdf-context' || element.type === 'csv-context') && element.files) {
                formData.append(`files-${element.id}`, element.files)
            }
        })

        ProtectedAxios.post('/users/addTemplate', formData)
            .then(res => {
                if (res.data) {
                    setTemplates(prev => {
                        let updatedTemplates = [...prev];
                        const currentTimestamp = new Date().toISOString();
                        let newTemplate = {
                            ...templateData,
                            template_id: res.data.new_template_id,
                            prompt: res.data.updated_prompt,
                            updated_at: currentTimestamp,
                            selectedCompany: templateData.selectedCompany,
                            company_url: templateData?.selectedCompany?.company_url,
                            company_name: templateData?.selectedCompany?.company_name,
                            selectedCategories: templateData.selectedCategories.sort((a, b) => {
                                return a.category.localeCompare(b.category);
                            }),
                        };
                        updatedTemplates.unshift(newTemplate)
                        return updatedTemplates;
                    });

                    setDialogState(false)
                    setSavingTemplate(false)
                    setTemplateData({ name: '', description: '', prompt: '', selectedCategories: [], selectedCompany: null, showing_additional_options: false, isSharingWithChildAccounts: true })

                    setTemplateBuilderStep(0)
                    setNewTemplateStructure([])
                }
            })
            .catch(err => {
                console.log(err);
                setSavingTemplate(false)

                if (err.response.status === 403) {
                    return toast.error(<div><p className='m-0'>You are using premium elements. Remove the premium elements or upgrade to Standard plan.</p><div className='d-flex align-items-center gap-2'><button className='button-plain-text text-primary' onClick={() => setTemplateBuilderStep(0)}><u>Edit</u></button> | <Link to="/upgrade">Upgrade</Link></div></div>)
                }

                toast.error("could not add template at the moment, please try again later.")
            })
    }

    return (
        <>
            <Dialog
                size="xl"
                backdrop="static"
                open={dialogState}
                onOpenChange={setDialogState}
                hideTrigger={true}
                title={`Create Template ${templateData?.typeText && `- ${templateData.typeText}`}`}
                bodyClass='p-0'
                body={
                    <>
                        {templateBuilderStep === 0
                            ?
                            <div id='template-builder'>
                                {!templateData.type
                                    ?
                                    <div className='container d-flex flex-column align-items-center justify-content-center'>
                                        <div>
                                            <h5 className='text-center font-l'>Select a template type to continue</h5>
                                            <p className='text-center'>Not sure which template type to select? Check out our guide on <a href="https://sagecollab.com/sagecollab-template-types" target='_blank' rel="noreferrer">SageCollab Templates</a></p>
                                        </div>

                                        <div className='d-flex flex-column align-items-center gap-5 mt-5'>
                                            <div className='d-flex justify-content-center gap-3 flex-wrap'>
                                                <button
                                                    className='button'
                                                    onClick={() => setTemplateData(prev => { return { ...prev, type: "CHAT_STARTER_PRIVATE", typeText: "Private Chat Started" } })}
                                                >
                                                    <RiLockLine className='w-s h-s' /> Private Chat Starter
                                                </button>

                                                <button
                                                    className='button button-border'
                                                    onClick={() => setTemplateData(prev => { return { ...prev, type: "CHAT_STARTER_OPEN", typeText: "Open Chat Starter" } })}
                                                >
                                                    <RiGlobalLine className='w-s h-s' /> Open Chat Starter
                                                </button>
                                            </div>

                                            <div className='chat-info'>
                                                <span>or</span>
                                            </div>

                                            <button
                                                className='button button-ghost'
                                                onClick={() => setTemplateData(prev => { return { ...prev, type: "NORMAL", typeText: "Normal" } })}
                                            >
                                                <HiOutlineTemplate className='w-s h-s' /> Normal
                                            </button>

                                        </div>
                                    </div>

                                    :
                                    <>
                                        <div className='left-panel'>
                                            <h5>Add elements</h5>
                                            {renderTemplateElements(templateData, newTemplateStructure, setNewTemplateStructure, setTemplateBuilderError)}
                                        </div>

                                        <div className='right-panel'>
                                            {renderSelectedTemplateElements(newTemplateStructure, setNewTemplateStructure, handleNewTemplateItemSort, templateBuilderError, setTemplateBuilderError)}
                                        </div>
                                    </>
                                }
                            </div>

                            :
                            <div id="template-builder">
                                <form className='left-panel p-0' onSubmit={addTemplate}>
                                    <div className='form-container'>
                                        <h5>Set template details</h5>
                                        {renderSelectedTemplateDetailsForm(templateData, setTemplateData, user, mode, subscriptionDetail)}
                                    </div>

                                    <div className='sticky-footer d-flex justify-content-between align-items-center' id="sticky-footer">
                                        <button type="button" className='button-plain-text d-flex align-items-center justify-content-center gap-2 font-xxs' onClick={() => setTemplateBuilderStep(0)}>
                                            <BsArrowLeftShort className='font-s' />
                                            Back
                                        </button>
                                        <div />
                                        <button className="button" disabled={savingTemplate}>
                                            {savingTemplate
                                                ?
                                                <>
                                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </>

                                                : 'Save'
                                            }
                                        </button>
                                    </div>
                                </form>

                                <div className='right-panel template-elements-container h-100 overflow-scroll'>
                                    <h5>Preview</h5>
                                    <div>
                                        {renderSelectedTemplatePreview(newTemplateStructure, setNewTemplateStructure)}
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
                footer={
                    <div className='d-flex flex-column-reverse flex-sm-row w-100  align-items-end align-items-sm-center justify-content-sm-between gap-2'>
                        <button
                            type="button"
                            className='button-plain-text d-flex align-items-center justify-content-center gap-2 font-xxs'
                            onClick={() => setChangeTemplateTypeConfirmationDialogState(true)}
                        >
                            <BsArrowLeftShort className='font-s' />
                            Change type
                        </button>
                        {templateBuilderStep === 0 && templateData.type
                            &&
                            <>
                                <button
                                    type="button"
                                    className='button d-flex align-items-center justify-content-center gap-2'
                                    onClick={() => {
                                        verifyTemplateBuilderStep(0, newTemplateStructure, setNewTemplateStructure, setTemplateBuilderStep, setTemplateBuilderError, setVerifyingTemplateStep, templateData, setTemplateData)
                                    }}
                                    disabled={verifyingTemplateStep}
                                >
                                    Next
                                    {verifyingTemplateStep
                                        ?
                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                        :
                                        <BsArrowRightShort />
                                    }
                                </button>
                            </>
                        }
                    </div>
                }
                hideFooter={!templateData.type}
            />

            <ChangeTemplateTypeConfirmationDialog
                dialogState={changeTemplateTypeConfirmationDialogState}
                setDialogState={setChangeTemplateTypeConfirmationDialogState}
                functionToExecuteOnConfirmation={() => {
                    setNewTemplateStructure([]);
                    setTemplateData(prev => ({ ...prev, type: "", typeText: "" }));
                    setChangeTemplateTypeConfirmationDialogState(false);
                }}
            />
        </>
    )
}

export default TemplateBuilderDialog;