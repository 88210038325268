import React, { useState, useRef, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // or any other icon library

const DEFAULT_SCROLL_THRESHOLD = 0.1; // 10% of the container's width, adjust as needed

const HorizontalScroller = ({
    children,
    scrollThreshold = DEFAULT_SCROLL_THRESHOLD,
    className = '', // optional wrapper class
}) => {
    const containerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(false);
    const debounceRef = useRef(null); // added for debouncing

    useEffect(() => {
        const handleResize = () => {
            updateScrollButtonsVisibility();
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        updateScrollButtonsVisibility();
    }, [scrollPosition]);

    const updateScrollButtonsVisibility = () => {
        if (!containerRef.current) return;
        const { scrollWidth, clientWidth, scrollLeft } = containerRef.current;
        const thresholdWidth = clientWidth * scrollThreshold;
    
        setShowLeftButton(scrollLeft > thresholdWidth);
        setShowRightButton(scrollLeft + clientWidth < scrollWidth - thresholdWidth);
    
        // Add/remove classes for gradient visibility
        const scroller = containerRef.current.parentElement;
        if (scroller) {
            if (scrollLeft > thresholdWidth) {
                scroller.classList.add('has-left-scroll');
            } else {
                scroller.classList.remove('has-left-scroll');
            }
    
            if (scrollLeft + clientWidth < scrollWidth - thresholdWidth) {
                scroller.classList.add('has-right-scroll');
            } else {
                scroller.classList.remove('has-right-scroll');
            }
        }
    };

    const handleScroll = (direction) => {
        if (!containerRef.current) return;
        const { clientWidth, scrollLeft } = containerRef.current;
        const newScrollLeft =
            direction === 'left'
                ? Math.max(0, scrollLeft - clientWidth / 2)
                : Math.min(scrollLeft + clientWidth / 2, containerRef.current.scrollWidth - clientWidth);

        const scrollOptions = { left: newScrollLeft, behavior: 'smooth' };
        clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
            containerRef.current.scrollTo(scrollOptions);
            setScrollPosition(newScrollLeft);
        }, 100);
    };

    return (
        <div className={`horizontal-scroller ${className}`}>
            {showLeftButton && (
                <button className="button-icon scroll-button left" onClick={() => handleScroll('left')}>
                    <FaChevronLeft className="font-xs" />
                </button>
            )}
            <div
                ref={containerRef}
                className="scroll-container"
                onScroll={(e) => setScrollPosition(e.target.scrollLeft)}
            >
                {children}
            </div>
            {showRightButton && (
                <button className="button-icon scroll-button right" onClick={() => handleScroll('right')}>
                    <FaChevronRight className="font-xs" />
                </button>
            )}
        </div>
    );
};

export default HorizontalScroller;
