import React, { useEffect, useMemo, useRef, useState } from 'react'
import MyChats from "../pages/MyChats"
import SharedChats from '../pages/SharedChats'
import OrganizationChats from '../pages/OrganizationChats'
import SavedPrompts from './SavedPrompts'
import { AiOutlineHistory } from "react-icons/ai"
import { RiUserReceivedLine } from 'react-icons/ri'
import { FiUsers } from 'react-icons/fi'
import { BiBookmark } from 'react-icons/bi'
import { HiOutlineTemplate } from 'react-icons/hi'
import useSubscriptionDetails from '../hooks/useSubscriptionDetails'
import { useLocation, useNavigate } from 'react-router-dom'
import Templates from '../pages/Templates'

const TabsWithView = () => {
    const [subscriptionDetail] = useSubscriptionDetails()

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const activeTab = parseInt(queryParams.get('activeTab')) || 0

    const [activeElement, setActiveElement] = useState(<MyChats />)
    let tabsRef = useRef(null)

    const navigate = useNavigate()

    useEffect(() => {
        if (activeTab === 0) {
            setActiveElement(<MyChats />)
        }
        else if (activeTab === 1) {
            setActiveElement(<SharedChats />)
        }
        else if (activeTab === 2) {
            setActiveElement(<OrganizationChats />)
        }
        else if (activeTab === 3) {
            setActiveElement(<SavedPrompts />)
        }
        else if (activeTab === 4) {
            setActiveElement(<Templates />)
        }
    }, [activeTab])

    const [isFooterVisible, setIsFooterVisible] = useState(false);
    const footer = document.getElementById("footer");

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.intersectionRatio >= 0.9) {
                    setIsFooterVisible(entry.isIntersecting);
                } else {
                    setIsFooterVisible(false);
                }
            }, {
                root: null,
                threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
            }
            ),
        [],
    );

    useEffect(() => {
        observer.observe(footer);

        return () => {
            observer.disconnect();
        };

    }, [footer, observer, location, activeElement, queryParams]);

    return (
        <section className='tabs-container'>
            <div className='tabs-render-area'>
                {activeElement}

            </div>

            <div className={`tabs ${isFooterVisible ? 'overlapped' : ''}`} ref={tabsRef}>
                <div className={`tab ${activeTab === 0 ? "active" : ""}`} onClick={() => navigate("/?activeTab=0")}><AiOutlineHistory />History</div>
                <div className={`tab ${activeTab === 1 ? "active" : ""}`} onClick={() => navigate("/?activeTab=1")}><RiUserReceivedLine />Shared</div>
                {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID
                    &&
                    <div className={`tab ${activeTab === 2 ? "active" : ""}`} onClick={() => navigate("/?activeTab=2")}><FiUsers />Organization</div>
                }
                <div className={`tab ${activeTab === 3 ? "active" : ""}`} onClick={() => navigate("/?activeTab=3")}><BiBookmark />Saved</div>
                <div className={`tab ${activeTab === 4 ? "active" : ""}`} onClick={() => navigate("/?activeTab=4")}><HiOutlineTemplate />Templates</div>
            </div>

        </section >
    )
}

export default TabsWithView