import { useContext, useEffect, useRef, useState } from "react"
import ProtectedAxios from "../api/protectedAxios"
import { toast } from "react-hot-toast"
import { MdArrowBack, MdSearch } from "react-icons/md"
import { BiCategoryAlt, BiFilter, BiSolidBuildings, BiUpArrowAlt } from "react-icons/bi"
import LoadingSkeleton from "../components/LoadingSkeleton"
import { UserContext } from "../context/UserProvider"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { GrFormClose } from "react-icons/gr"
import { AiFillStar, AiFillTag, AiOutlineStar } from "react-icons/ai"
import { HiOutlineTemplate } from "react-icons/hi"
import { Helmet } from "react-helmet"
import MyDropdown from "../components/MyDropdown"
import { TbArrowsSort } from "react-icons/tb";
import { IoChatboxOutline, IoEyeOutline } from "react-icons/io5"
import { PiDotOutlineFill } from "react-icons/pi"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Fade, OverlayTrigger, Tooltip } from 'react-bootstrap'
import HorizontalScroller from "../components/ui/HorizontailScroller"

const TemplateMarketplace = ({ showHeader = true }) => {
    const [user] = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();


    //--------------------------------------------------------------------------
    // Filters
    //--------------------------------------------------------------------------
    const [dropdownFilterActiveTab, setDropdownFilterActiveTab] = useState("categories");
    const [dropdownFilterVisible, setDropdownFilterVisible] = useState(false);
    const dropdownFilterRef = useRef(null);

    useClickOutside(dropdownFilterRef);
    function useClickOutside(ref) {
        useEffect(() => {
            // Alert if clicked on outside of element
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setDropdownFilterVisible(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        if (dropdownFilterVisible) {
            if (dropdownFilterActiveTab === "categories") {
                fetchMarketPlaceCategories();
            } else if (dropdownFilterActiveTab === "companies") {
                fetchMarketPlaceCompanies();
            }
        }
    }, [dropdownFilterActiveTab, dropdownFilterVisible]);


    /**
     * Favourite filter --------------------------------------------------------
     */
    const [isFavouriteFilterOn, setIsFavouriteFilterOn] = useState(false)
    const [togglingFavourite, setTogglingFavourite] = useState(false)
    const toggleFavourite = (currentState, template_id) => {
        setTogglingFavourite(true);
        if (currentState === 0) {
            ProtectedAxios.post("/users/addMarketplaceTemplateAsFavourite", { user_id: user.user_id, template_id })
                .then(res => {
                    if (res.data) {
                        setTemplates(prev => {
                            let updatedTemplate = [...prev];
                            const index = updatedTemplate.findIndex(template => template.template_id === template_id);
                            updatedTemplate[index].is_favourite = 1;
                            return updatedTemplate
                        })
                        setTogglingFavourite(false);
                    }
                })
                .catch(err => {
                    setTogglingFavourite(false);
                    console.log(err);
                    toast.error(err.response.data.error || err.response.statusText || "Could not add template as favourite at the moment, please try again later");
                })
        } else {
            ProtectedAxios.post("/users/removeMarketplaceTemplateAsFavourite", { user_id: user.user_id, template_id })
                .then(res => {
                    if (res.data) {
                        if (isFavouriteFilterOn) {
                            setTemplates(prev => {
                                let updatedTemplate = [...prev];
                                updatedTemplate = updatedTemplate.filter(template => template.template_id !== template_id);
                                return updatedTemplate;
                            })
                        }
                        else {
                            setTemplates(prev => {
                                let updatedTemplate = [...prev];
                                const index = updatedTemplate.findIndex(template => template.template_id === template_id);
                                updatedTemplate[index].is_favourite = 0;
                                return updatedTemplate;
                            })
                        }
                        setTogglingFavourite(false);
                    }
                })
                .catch(err => {
                    setTogglingFavourite(false);
                    console.log(err);
                    toast.error(err.response.data.error || err.response.statusText || "Could not remove template as favourite at the moment, please try again later");
                })
        }

    }


    /**
     * Text filter -------------------------------------------------------------
     */
    const [templateSearchText, setTemplateSearchText] = useState("");
    const [searchingWithText, setSearchingWithText] = useState(false);
    const [searchText, setSearchText] = useState(false)


    /**
     * Type filter -------------------------------------------------------------
     */
    const [typeFilter, setTypeFilter] = useState("all");

    const handleTabClick = (tab) => {
        setTypeFilter(tab);

        // Get the clicked tab button element
        const tabButton = document.querySelector(`.tab-button-${tab}`);

        // Get the sliding background element
        const activeTabBackground = document.querySelector('#active-tab-background');

        // Update the position and width of the sliding background
        activeTabBackground.style.left = `${tabButton.offsetLeft}px`;
        activeTabBackground.style.width = `${tabButton.offsetWidth}px`;
    };

    useEffect(() => {
        // Get the clicked tab button element
        const tabButton = document.querySelector(`.tab-button-${typeFilter}`);

        // Get the sliding background element
        const activeTabBackground = document.querySelector('#active-tab-background');

        if (tabButton && activeTabBackground) {
            // Update the position and width of the sliding background
            activeTabBackground.style.left = `${tabButton.offsetLeft}px`;
            activeTabBackground.style.width = `${tabButton.offsetWidth}px`;
        }
    }, [])


    /**
     * Sort filter -------------------------------------------------------------
     */
    const [selectedSortOption, setSelectedSortOption] = useState("NEWEST_FIRST");


    /**
     * Company filter -------------------------------------------------------------
     */
    const [companies, setCompanies] = useState([]);
    const [backupCompanies, setBackupCompanies] = useState([]);
    const [fetchingCompanies, setFetchingCompanies] = useState(false);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [searchCompanyText, setSearchCompanyText] = useState("");

    const fetchMarketPlaceCompanies = () => {
        setFetchingCompanies(true);
        ProtectedAxios.post("/users/fetchMarketPlaceCompanies", { selectedCompanies, templateSearchText, type_filter: typeFilter })
            .then(res => {
                setCompanies(res.data);
                setBackupCompanies(res.data);
                setFetchingCompanies(false);
            })
            .catch(err => {
                console.log(err);
                toast.error("Could not fetch companies at the moment please try again later.");
                setFetchingCompanies(false);
            })
    }
    const filterCompanyList = () => {
        if (searchCompanyText.length > 0) {
            const updatedList = backupCompanies.filter(company => {
                return company.company_name.toLowerCase().includes(searchCompanyText.toLowerCase())
            })
            setCompanies(updatedList)
        } else {
            setCompanies(backupCompanies)
        }
    }
    const selectCompany = (_company) => {
        //check if the company has already been selected
        let indexOfSelectedCompany = selectedCompanies.findIndex(company => company.company_id === _company.company_id);
        if (indexOfSelectedCompany === -1) {
            setSearchCompanyText("");
            setSelectedCompanies(prev => {
                let updatedCompanies = [...prev];
                updatedCompanies.push(_company);
                return updatedCompanies;
            })

            // update the `companies` URL param
            const searchParams = new URLSearchParams(location.search);
            const companiesParams = searchParams.get('companies') ? searchParams.get('companies').split(',') : [];
            companiesParams.push(_company.company_name);
            searchParams.set('companies', companiesParams.join(','));
            navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
        }
    }
    const removeCompany = (_company) => {
        const filteredCompanies = selectedCompanies.filter(company => company.company_id !== _company.company_id);
        setSelectedCompanies(filteredCompanies);

        // update the `companies` URL param
        const searchParams = new URLSearchParams(location.search);
        const companiesParams = searchParams.get('companies').split(',');
        const filteredCompaniesParams = companiesParams.filter(company => company.toLocaleLowerCase() !== _company.company_name.toLowerCase());
        if (filteredCompaniesParams.length > 0) {
            searchParams.set('companies', filteredCompaniesParams.join(','));
        } else {
            searchParams.delete('companies');
        }
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });

        // NOTE: since search params i.e. location is updated, fetchTempaltes()
        // function will be called automatically because of useEffect
    }

    useEffect(() => {
        filterCompanyList();
    }, [searchCompanyText]);

    /**
     * Category filter -------------------------------------------------------------
     */
    const [categories, setCategories] = useState([])
    const [backupCategories, setBackupCategories] = useState([])
    const [fetchingCategories, setFetchingCategories] = useState(false)
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [searchCategoryText, setSearchCategoryText] = useState("");

    const fetchMarketPlaceCategories = () => {
        setFetchingCategories(true);
        ProtectedAxios.post("/users/fetchMarketPlaceCategories", { selectedCategories, templateSearchText, type_filter: typeFilter })
            .then(res => {
                setCategories(res.data);
                setBackupCategories(res.data);
                setFetchingCategories(false);
            })
            .catch(err => {
                console.log(err);
                toast.error("Could not fetch categories at the moment please try again later.");
                setFetchingCategories(false);
            })
    }
    const filterCategoryList = () => {
        if (searchCategoryText.length > 0) {
            const updatedList = backupCategories.filter(category => {
                return category.category.toLowerCase().includes(searchCategoryText.toLowerCase())
            })
            setCategories(updatedList)
        } else {
            setCategories(backupCategories)
        }
    }
    const selectCategory = (_category) => {
        //check if the category has already been selected
        let indexOfSelectedCategory = selectedCategories.findIndex(category => category.category_id === _category.category_id);
        if (indexOfSelectedCategory === -1) {
            setSearchCategoryText("");
            setSelectedCategories(prev => {
                let updatedCategories = [...prev];
                updatedCategories.push(_category);
                return updatedCategories;
            })

            // update the `categories` URL param
            const searchParams = new URLSearchParams(location.search);
            const categoriesParams = searchParams.get('categories') ? searchParams.get('categories').split(',') : [];
            categoriesParams.push(_category.category);
            searchParams.set('categories', categoriesParams.join(','));
            navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
        }
    }
    const removeCategory = (_category) => {
        const filteredCategories = selectedCategories.filter(category => category.category_id !== _category.category_id);
        setSelectedCategories(filteredCategories);

        // update the `categories` URL param
        const searchParams = new URLSearchParams(location.search);
        const categoriesParams = searchParams.get('categories').split(',');
        const filteredCategoriesParams = categoriesParams.filter(category => category.toLocaleLowerCase() !== _category.category.toLowerCase());
        if (filteredCategoriesParams.length > 0) {
            searchParams.set('categories', filteredCategoriesParams.join(','));
        } else {
            searchParams.delete('categories');
        }
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });

        // NOTE: since search params i.e. location is updated, fetchTempaltes()
        // function will be called automatically because of useEffect
    }

    useEffect(() => {
        filterCategoryList();
    }, [searchCategoryText]);


    //--------------------------------------------------------------------------
    // Templates
    //--------------------------------------------------------------------------
    const [page, setPage] = useState(1);
    const [pageEnded, setPageEnded] = useState(false);
    const [fetchingTemplates, setFetchingTemplates] = useState(true);
    const [fetchingMoreTemplates, setFetchingMoreTemplates] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const templatesContainerEndRef = useRef(null);

    const getCompaniesFromSearchParams = () => {
        const searchParams = new URLSearchParams(location.search);
        const companySearchParam = searchParams.get("companies");
        return companySearchParam ? companySearchParam.split(",") : [];
    }
    const getCategoriesFromSearchParams = () => {
        const searchParams = new URLSearchParams(location.search);
        const categorySearchParam = searchParams.get("categories");
        return categorySearchParam ? categorySearchParam.split(",") : [];
    }
    const fetchMarketplaceTemplates = (e) => {
        if (e) {
            e?.preventDefault();
        }
        setPageEnded(false);
        setPage(1);
        if (templateSearchText.length > 0) {
            setSearchingWithText(true);
            setSearchText(templateSearchText);
        } else {
            setSearchingWithText(false);
        }
        const selectedCompanies = getCompaniesFromSearchParams();
        const selectedCategories = getCategoriesFromSearchParams();
        setFetchingTemplates(true);
        ProtectedAxios.post("/users/fetchMarketPlaceTemplates", { user_id: user.user_id, templateSearchText, selectedCategories, selectedCompanies, page: 1, favourite_filter: isFavouriteFilterOn, type_filter: typeFilter, sort_type: selectedSortOption })
            .then(res => {
                setTemplates(res.data.templates);
                setSelectedCategories(res.data.validSelectedCategories);
                setSelectedCompanies(res.data.validSelectedCompanies);
                setFetchingTemplates(false);
            })
            .catch(err => {
                console.log(err)
                toast.error("Could not fetch marketplace templates at the moment please try again later.")
                setFetchingTemplates(false);
            })
    }
    const loadMoreMarketplaceTemplates = () => {
        setFetchingMoreTemplates(true);
        let updatedPage = page + 1;
        setPage(page => page + 1);
        const selectedCategories = getCategoriesFromSearchParams();
        const selectedCompanies = getCompaniesFromSearchParams();
        ProtectedAxios.post("/users/fetchMarketPlaceTemplates", { user_id: user.user_id, templateSearchText, selectedCategories, selectedCompanies, page: updatedPage, favourite_filter: isFavouriteFilterOn, type_filter: typeFilter, sort_type: selectedSortOption })
            .then(res => {
                if (res.data.templates.length < 4 || res.data.templates.length === 0) {
                    setPageEnded(true);
                }
                setTemplates(prev => [...prev, ...res.data.templates]);
                setSelectedCategories(res.data.validSelectedCategories);
                setSelectedCompanies(res.data.validSelectedCompanies);
                setFetchingMoreTemplates(false);
            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.error || "Could not load templates please refresh the page");
                setFetchingMoreTemplates(false);
            })
    }
    const handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                loadMoreMarketplaceTemplates()
            }
        });
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };
        const observer = new IntersectionObserver(handleIntersection, options);
        const loadMoreButton = templatesContainerEndRef.current;

        if (loadMoreButton) {
            observer.observe(loadMoreButton);
        }

        return () => {
            if (loadMoreButton) {
                observer.unobserve(loadMoreButton);
            }
        };
    }, [fetchingTemplates, fetchingMoreTemplates]);
    useEffect(() => {
        if (templateSearchText.length === 0) {
            fetchMarketplaceTemplates();
        }
    }, [templateSearchText]);
    useEffect(() => {
        fetchMarketplaceTemplates();
    }, [isFavouriteFilterOn, typeFilter, selectedSortOption, location.search]);


    //--------------------------------------------------------------------------
    // Highlighted categories
    //--------------------------------------------------------------------------
    const [highlightedCategories, setHighlightedCategories] = useState([]);
    const [loadingHighlightedCategories, setLoadingHighlightedCategories] = useState(true);

    const fetchHighlightedCategories = () => {
        setLoadingHighlightedCategories(true);
        ProtectedAxios.get("/users/getHighlightedTemplateCategories")
            .then(res => {
                setHighlightedCategories(res.data);
                setLoadingHighlightedCategories(false);
            })
            .catch(err => {
                console.log(err);
                setLoadingHighlightedCategories(false);
            })
    }

    useEffect(() => {
        fetchHighlightedCategories();
    }, []);


    //--------------------------------------------------------------------------
    // Install template
    //--------------------------------------------------------------------------
    const [installingTemplate, setInstallingTemplate] = useState(false);

    const installTemplate = (_template) => {
        if (!user?.user_id) {
            toast('Login/signup to continue');
            navigate(`/login?redirectTo=/marketplace/template/${_template?.template_id}`);
            return;
        }

        setInstallingTemplate(true);
        const startTime = performance.now();
        ProtectedAxios.post("/users/installTemplate", { user_id: user.user_id, selectedTemplate: _template })
            .then(res => {
                if (res.data) {
                    const endTime = performance.now()
                    const timeTaken = endTime - startTime
                    const mockWaitingTime = 3000;
                    const waitingTime = mockWaitingTime - timeTaken
                    setTimeout(() => {
                        //update to current template in templates list and set it to "installed"
                        const indexOfSelectedTemplate = templates.findIndex(template => template.template_id === _template.template_id);
                        setTemplates(prev => {
                            const updatedTemplates = [...prev];
                            updatedTemplates[indexOfSelectedTemplate] = { ..._template, installed: 1, download_count: res.data.download_count };
                            return updatedTemplates;
                        });
                        setInstallingTemplate(false)
                    }, waitingTime);
                }
            })
            .catch(err => {
                console.log(err);
                setInstallingTemplate(false);
                toast.error(err.response.data.error || err.response.statusText || "Could not install templates at the moment, please try again later");
            })
    }


    return (
        <div className='container py-5 mb-5'>
            {user?.user_id
                ?
                showHeader
                &&
                <div className="d-flex align-items-center gap-2">
                    <Helmet>
                        <title>Template Marketplace - SageCollab</title>
                    </Helmet>
                    <button type="submit" className='button-icon edit-btn' title='back' onClick={() => navigate(-1)}><MdArrowBack className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                    <h2 className="m-0">Template Marketplace</h2>
                </div>

                :
                <div>
                    <Helmet>
                        <title>Home - SageCollab</title>
                        <meta name="description" content="SageCollab brings team collaboration features to generative AI." />
                        <meta name="keywords" content="SageCollab, sagecollab app, team collaboration, collaboration, generative AI, generative AI collaboration" />
                        <meta property="og:title" content="Home - SageCollab" />
                        <meta property="og:description" content="SageCollab brings team collaboration features to generative AI." />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="https://app.sagecollab.com" />
                        <meta property="og:image" content="https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg" />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" content="Home - SageCollab" />
                        <meta name="twitter:description" content="SageCollab brings team collaboration features to generative AI." />
                        <meta name="twitter:image" content="https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg" />
                    </Helmet>
                    <h1 className=" font-l">SageCollab brings team collaboration features to generative AI.</h1>
                    <div className="mobile-signup-container">
                        <NavLink to="/login" className="button button-ghost">Login</NavLink>
                        <span />
                        <NavLink to="/register" className="button">Register</NavLink>
                    </div>
                    <br />
                    <p className="">Empower AI chats and knowledge management. Store, categorize, and search AI chat history; enrich with personalized notes, foster team collaboration, and harness the potential of prompt templates.</p>
                    <h2 className="font-xs fw-medium">Sign up now and start using these prompt templates with your AI chat.</h2>
                    <br />
                </div>
            }

            <div className="mt-5">
                {loadingHighlightedCategories
                    ?
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='d-flex gap-2 mt-3 px-4' style={{ width: "75%" }}>
                            <LoadingSkeleton type="tags" />
                            <LoadingSkeleton type="tags" />
                            <LoadingSkeleton type="tags" />
                            <LoadingSkeleton type="tags" />
                        </div>
                        <div className='d-flex gap-2 mt-3 px-4'>
                            <LoadingSkeleton type="icon" />
                            <LoadingSkeleton type="icon" />
                        </div>
                    </div>
                    :
                    <div className="d-flex align-items-center justify-content-between gap-4">
                        {highlightedCategories.length === 0
                            ?
                            <div />

                            :
                            <HorizontalScroller>
                                <div className='used-tags me-4'>
                                    {highlightedCategories.map((category, i) => {
                                        return (
                                            <div key={i} id={`used-tag-${category.category_id}`} className={`used-tag ${selectCategory === category && 'active'}`} onClick={() => { window.scrollTo(0, 0); selectCategory(category) }}>{category.category}</div>
                                        )
                                    })}
                                </div>
                            </HorizontalScroller>
                        }

                        <div className="d-flex align-items-center" >
                            <div className={`category-select-dropdown ${dropdownFilterVisible ? 'dropdown-active' : ''} position-relative`}>
                                <button className='button-icon' onClick={() => setDropdownFilterVisible(prev => !prev)}>
                                    <BiFilter className='w-xl h-xl' />
                                </button>
                                <div ref={dropdownFilterRef} className={`searchable-dropdown ${dropdownFilterVisible ? 'dropdown-active' : ''}`}>
                                    <div className="dropdown-content">
                                        <div className='dropdown-title d-md-none'>
                                            <p>Filter templates</p>
                                        </div>
                                        <Tabs
                                            defaultActiveKey={dropdownFilterActiveTab}
                                            onSelect={e => setDropdownFilterActiveTab(e)}
                                            className="mb-3 profile-tabs pt-2"
                                            transition={Fade}
                                            justify
                                        >
                                            <Tab
                                                eventKey="categories"
                                                title={
                                                    <div className='d-flex justify-content-center align-items-center gap-2'>
                                                        <p className='m-0'>Category</p>
                                                    </div>
                                                }
                                            >
                                                <div>
                                                    <div className="dropdown-header border-0">
                                                        <input type='text' placeholder='search category' value={searchCategoryText} onChange={e => setSearchCategoryText(e.target.value)} />
                                                    </div>
                                                    <div>
                                                        <div className="category-list">
                                                            {
                                                                fetchingCategories
                                                                    ?
                                                                    <div className="d-flex mt-4 w-100 h-100 gap-2 justify-content-center align-items-center flex-column">
                                                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                            <span className="sr-only"></span>
                                                                        </div>
                                                                        <p className="font-xxs">
                                                                            Loading categories
                                                                        </p>
                                                                    </div>
                                                                    :
                                                                    categories.length === 0
                                                                        ?
                                                                        <div className="d-flex mt-4 w-100 h-100 justify-content-center align-items-center flex-column">
                                                                            <BiCategoryAlt className="fs-3" />
                                                                            <p className="font-xxs">
                                                                                Nothing found
                                                                            </p>
                                                                        </div>
                                                                        :
                                                                        <div className="dropdown-categories">
                                                                            {
                                                                                categories.map((category, i) => {
                                                                                    return (
                                                                                        <div key={i} onClick={() => { selectCategory(category); setDropdownFilterVisible(false) }} className={`filter-categories-category tag tag-color-${i % 4 + 1}`}>
                                                                                            {category.category}
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab
                                                eventKey="companies"
                                                title={
                                                    <div className='d-flex justify-content-center align-items-center gap-2'>
                                                        <p className='m-0'>Company</p>
                                                    </div>
                                                }
                                            >
                                                <div>
                                                    <div className="dropdown-header border-0">
                                                        <input type='text' placeholder='search company' value={searchCompanyText} onChange={e => setSearchCompanyText(e.target.value)} />
                                                    </div>
                                                    <div>
                                                        <div className="category-list">
                                                            {
                                                                fetchingCompanies
                                                                    ?
                                                                    <div className="d-flex mt-4 w-100 h-100 gap-2 justify-content-center align-items-center flex-column">
                                                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                            <span className="sr-only"></span>
                                                                        </div>
                                                                        <p className="font-xxs">
                                                                            Loading companies
                                                                        </p>
                                                                    </div>
                                                                    :
                                                                    companies.length === 0
                                                                        ?
                                                                        <div className="d-flex mt-4 w-100 h-100 justify-content-center align-items-center flex-column">
                                                                            <BiCategoryAlt className="fs-3" />
                                                                            <p className="font-xxs">
                                                                                Nothing found
                                                                            </p>
                                                                        </div>
                                                                        :
                                                                        <div className="dropdown-categories">
                                                                            {
                                                                                companies.map((company, i) => {
                                                                                    return (
                                                                                        <div key={i} onClick={() => { selectCompany(company); setDropdownFilterVisible(false) }} className={`filter-categories-category tag tag-color-${i % 4 + 1}`}>
                                                                                            {company.company_name}
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>

                            {user?.user_id
                                &&
                                <button title={isFavouriteFilterOn ? 'show all' : 'show favourites'} className={`edit-btn button-favourite-filter-toggle ${isFavouriteFilterOn ? 'on' : ''}`} disabled={fetchingTemplates} onClick={() => { setIsFavouriteFilterOn(prev => !prev); }}>
                                    {isFavouriteFilterOn
                                        ? <AiFillStar className='edit-icon w-l h-l' style={{ color: 'var(--color-golden)' }} />
                                        : <AiOutlineStar className='edit-icon w-l h-l' style={{ color: 'var(--color-dark)' }} />
                                    }
                                </button>
                            }
                        </div>
                    </div>
                }
            </div>

            <div className='template-header'>
                <div className='w-100 d-flex justify-content-end align-items-center my-4 gap-3'>
                    <form className='input-with-icon w-100' onSubmit={fetchMarketplaceTemplates}>
                        <input
                            className='form-input'
                            type="text"
                            placeholder="search template"
                            value={templateSearchText}
                            onChange={e => setTemplateSearchText(e.target.value)}
                        />
                        {templateSearchText.length
                            ?
                            <button type="submit" className='button-icon' title='search'><MdSearch className="font-m" /></button>
                            :
                            <></>
                        }
                    </form>

                    <MyDropdown
                        disabled={templates.length === 0}
                        position='left'
                        dropdownButton={
                            <div className='dropdown-link sort-dropdown dropdown'>
                                <button
                                    disabled={templates.length === 0}
                                    className='dropdown-toggle gap-1'
                                >
                                    <TbArrowsSort className="w-s" />
                                    {selectedSortOption === "NEWEST_FIRST"
                                        ? "Newest"
                                        :
                                        selectedSortOption === "OLDEST_FIRST"
                                            ? "Oldest"
                                            :
                                            selectedSortOption === "MOST_INSTALLED"
                                                ? "Most Installed"
                                                : "Least Installed"
                                    }
                                </button>
                            </div>
                        }
                        title="Sort Templates"
                    >
                        <div className={`dropdown-item ${selectedSortOption === "NEWEST_FIRST" ? "active" : ""}`} onClick={() => { setSelectedSortOption("NEWEST_FIRST") }}>Newest First</div>
                        <div className={`dropdown-item ${selectedSortOption === "OLDEST_FIRST" ? "active" : ""}`} onClick={() => { setSelectedSortOption("OLDEST_FIRST") }}>Oldest First</div>
                        <div className={`dropdown-item ${selectedSortOption === "MOST_INSTALLED" ? "active" : ""}`} onClick={() => { setSelectedSortOption("MOST_INSTALLED") }}>Most Installed</div>
                        <div className={`dropdown-item ${selectedSortOption === "LEAST_INSTALLED" ? "active" : ""}`} onClick={() => { setSelectedSortOption("LEAST_INSTALLED") }}>Least Installed</div>
                    </MyDropdown>
                </div>
            </div>

            <div className="applied-filters">
                {searchingWithText && templateSearchText.length > 0
                    &&
                    <span className={`tag tag-search editing`}>
                        <MdSearch className="font-xs me-1" />
                        {searchText}
                        <button type='button' title='remove search filter' id='remove-tag-button' className='edit-btn remove-tag-button ms-2' onClick={e => { setTemplateSearchText(""); setSearchText("") }}><GrFormClose className={`edit-icon`} /></button></span>
                }
                {selectedCompanies.length > 0
                    &&
                    selectedCompanies.map((company, i) => {
                        return (
                            <span key={i} className={`tag tag-color-${(searchingWithText ? i + 1 : i) % 4 + 1} editing`}>
                                <BiSolidBuildings className="font-xs me-1" />
                                {company.company_name}
                                <button type='button' title='remove company filter' id='remove-tag-button' className='edit-btn remove-tag-button ms-2' onClick={e => { removeCompany(company) }}><GrFormClose className={`edit-icon`} /></button>
                            </span>
                        )
                    })
                }
                {selectedCategories.length > 0
                    &&
                    selectedCategories.map((category, i) => {
                        return (
                            <span key={i} className={`tag tag-color-${(searchingWithText ? i + 1 : i) + (selectedCompanies.length) % 4 + 1} editing`}>
                                <AiFillTag className="font-xs me-1" />
                                {category.category}
                                <button type='button' title='remove category filter' id='remove-tag-button' className='edit-btn remove-tag-button ms-2' onClick={e => { removeCategory(category) }}><GrFormClose className={`edit-icon`} /></button>
                            </span>
                        )
                    })
                }
            </div>

            <div className="d-flex flex-column justify-content-center justify-content-md-end align-items-end gap-4 mb-4">
                <div className="custom-tabs">
                    <div id="active-tab-background"></div>
                    {['all', 'official', 'community'].map((tab) => (
                        <button
                            key={tab}
                            className={`tab-button tab-button-${tab}  ${typeFilter === tab ? 'active-tab' : ''}`}
                            onClick={() => handleTabClick(tab)}
                        >
                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                        </button>
                    ))}
                </div>
            </div>


            {fetchingTemplates
                ?
                <div className="prompt-list-container">
                    <LoadingSkeleton type='chat' />
                    <LoadingSkeleton type='chat' />
                    <LoadingSkeleton type='chat' />
                    <LoadingSkeleton type='chat' />
                </div>

                :
                templates.length === 0
                    ?
                    <div className="template-container d-flex gap-1 justify-content-center align-items-center flex-column">
                        <HiOutlineTemplate className="fs-3" />
                        <p>
                            No templates found
                        </p>
                    </div>

                    :
                    <div className='template-container'>
                        {templates.map((template, i) => {
                            return (
                                <div key={i} className="marketplace-template">
                                    <div className="progress-container"><span className={`my-progress ${selectedTemplate?.template_id === template.template_id && installingTemplate ? 'on' : ''}`} /></div>
                                    {user?.user_id
                                        &&
                                        <button disabled={togglingFavourite && selectedTemplate?.template_id === template.template_id} className={`button-favourite edit-btn ${togglingFavourite && selectedTemplate?.template_id === template.template_id ? 'toggling' : ''}`} onClick={() => { toggleFavourite(template.is_favourite, template.template_id); setSelectedTemplate(template) }}>
                                            {template.is_favourite
                                                ?
                                                <AiFillStar className="favourite-icon" />
                                                :
                                                <AiOutlineStar className="not-favourite-icon" />
                                            }
                                        </button>
                                    }
                                    <div className="template-body mt-1">
                                        <div>
                                            <NavLink className="template-name hover-underline" to={`/marketplace/template/${template.template_id}`}>
                                                {template.name}
                                            </NavLink>
                                            <div className="company-container gap-4 mt-2 flex-wrap">
                                                <p className="company-name">
                                                    {/* By&nbsp; */}
                                                    {(template.created_user_role_id === 1 || template?.company_url)
                                                        ?
                                                        <a href={template.company_url} target="_blank" rel="noreferrer">{template.company_name}</a>

                                                        :
                                                        <div className="d-flex align-items-center gap-1 cursor-pointer">
                                                            <img src={template.created_user_profile_picture_src} alt="profile" className="profile-image w-m h-m" />
                                                            <NavLink to={`/user/${template.created_user_username}`} className="m-0">{template.created_user_username}</NavLink>
                                                        </div>
                                                    }
                                                </p>
                                                {/* <PiDotOutlineFill style={{ fill: "var(--color-light)" }} /> */}
                                                <div className="d-flex align-items-center gap-2">
                                                    <OverlayTrigger
                                                        overlay={(props) => (
                                                            <Tooltip {...props}>
                                                                Installs
                                                            </Tooltip>
                                                        )}
                                                    >
                                                        <div style={{ fontSize: "var(--fs-5)" }} className="d-flex justify-content-center align-items-center gap-1 cursor-pointer">
                                                            <BiUpArrowAlt />
                                                            {template?.download_count?.toLocaleString()}
                                                        </div>
                                                    </OverlayTrigger>

                                                    <PiDotOutlineFill style={{ fill: "var(--color-light)" }} />

                                                    <OverlayTrigger
                                                        overlay={(props) => (
                                                            <Tooltip {...props}>
                                                                Views
                                                            </Tooltip>
                                                        )}
                                                    >
                                                        <div style={{ fontSize: "var(--fs-5)" }} className="d-flex justify-content-center align-items-center gap-1 cursor-pointer">
                                                            <IoEyeOutline />
                                                            {template?.views?.toLocaleString()}
                                                        </div>
                                                    </OverlayTrigger>

                                                    <PiDotOutlineFill style={{ fill: "var(--color-light)" }} />

                                                    <OverlayTrigger
                                                        overlay={(props) => (
                                                            <Tooltip {...props}>
                                                                Use count
                                                            </Tooltip>
                                                        )}
                                                    >
                                                        <div style={{ fontSize: "var(--fs-5)" }} className="d-flex justify-content-center align-items-center gap-1 cursor-pointer">
                                                            <IoChatboxOutline />
                                                            {template?.used_count?.toLocaleString()}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="template-description text-truncate">{template.description}</div>
                                        <div>
                                            <div className="template-categories">
                                                {template.selectedCategories.map((category, i) => {
                                                    return (
                                                        <>
                                                            <div key={i} className={`category `} onClick={() => { window.scrollTo(0, 0); selectCategory(category) }}>
                                                                {category.category}
                                                            </div>
                                                            &nbsp;
                                                        </>
                                                    )
                                                })
                                                }
                                            </div>
                                            <div className="template-footer">
                                                <div />
                                                <div className="d-flex gap-2 justify-content-end mt-">
                                                    {user?.user_id
                                                        // ? <button onClick={() => navigate(`/start-chat?templateId=${template.template_id}`)}>{template.installed ? 'Use' : 'Try'}</button>
                                                        ? <NavLink to={`/start-chat?templateId=${template.template_id}`} className="button button-border bg-transparent">{template.installed ? 'Use' : 'Try'}</NavLink>
                                                        : <NavLink to={`/marketplace/template/${template.template_id}`} className="button button-border bg-transparent">View</NavLink>
                                                    }
                                                    {/* {user?.user_id
                            &&
                            <button className={`button button-border`} onClick={() => navigate(`/start-chat?templateId=${template.template_id}`)}>{template.installed ? 'Use' : 'Try'}</button>
                          } */}
                                                    {template.type !== "CHAT_STARTER_PRIVATE"
                                                        &&
                                                        <button className={`button ${template.installed === 1 ? "button-borde" : ""}`} onClick={() => { setSelectedTemplate(template); installTemplate(template) }} disabled={selectedTemplate?.template_id === template.template_id && installingTemplate}>
                                                            {selectedTemplate?.template_id === template.template_id && installingTemplate
                                                                ?
                                                                <>
                                                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                        <span className="sr-only"></span>
                                                                    </div>
                                                                </>

                                                                :
                                                                template.installed === 0
                                                                    ? "Install"
                                                                    : "Install Again"
                                                            }
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {!pageEnded
                            ?
                            fetchingMoreTemplates
                                ?
                                <div className='prompt-list-container m-0 mt-2'>
                                    <LoadingSkeleton type='chat' />
                                    <LoadingSkeleton type='chat' />
                                    <LoadingSkeleton type='chat' />
                                    <LoadingSkeleton type='chat' />
                                </div>

                                :
                                <div ref={templatesContainerEndRef} />
                            : ""
                        }
                    </div >
            }
        </div >
    )
}

export default TemplateMarketplace