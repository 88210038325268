import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { UserContext } from '../context/UserProvider';
import { PiShareFill } from "react-icons/pi";
import SwitchButton from './ui/SwitchButton';
import ProtectedAxios from '../api/protectedAxios';
import toast from 'react-hot-toast';
import { IoCheckmark, IoCopy } from 'react-icons/io5';
import { TbWorldShare } from "react-icons/tb";
import { formatDate } from '../utils/helper';
import { BiLink } from 'react-icons/bi';
import { GoTriangleDown, GoTriangleUp } from "react-icons/go"
import { MdClose, MdRefresh } from 'react-icons/md';

const ShareChat = ({ chat_id, title, showAsLink, chatDetails, onChatDetailsChange, className }) => {
    const [user] = useContext(UserContext)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [googleAccordionState, setGoogleAccordionState] = useState(false)
    const [visibleOnGoogle, setVisibleOnGoogle] = useState(false)
    const [togglingGoogleVisibility, setTogglingGoogleVisibility] = useState(false)

    const [indexingData, setIndexingData] = useState(null)
    const [loadingIndexingData, setLoadingIndexingData] = useState(false)

    const [sharing, setSharing] = useState(false)
    const [sharedTimestamp, setSharedTimestamp] = useState(null)
    const [updatingChatSharingStatus, setUpdatingChatSharingStatus] = useState(false)
    const [updatingChatSharingTime, setUpdatingChatSharingTime] = useState(false)

    const [chatLink, setChatLink] = useState("")
    const [copied, setCopied] = useState(false)
    useEffect(() => {
        if (copied === true) {
            setTimeout(() => {
                setCopied(false)
            }, 1000)
        }
    }, [copied])

    useEffect(() => {
        setVisibleOnGoogle(chatDetails.visible_on_google)

        setSharing(chatDetails.is_sharing)
        setSharedTimestamp(chatDetails.shared_timestamp)
        setChatLink(`${window.location.origin}/chat/shared/${chat_id}`)
    }, [chatDetails])

    const toggleChatSharing = () => {
        setUpdatingChatSharingStatus(true)
        ProtectedAxios.post("/users/toggleChatSharing", { user_id: user.user_id, chat_id })
            .then(res => {
                setUpdatingChatSharingStatus(false)

                setSharing(res.data.is_sharing)
                setSharedTimestamp(res.data.shared_timestamp)

                onChatDetailsChange(res.data)
            })
            .catch(err => {
                setUpdatingChatSharingStatus(false)
                console.log(err);
                toast.error(err.response.data.error)
            })
    }

    const updateChatSharingTimeToLatest = () => {
        setUpdatingChatSharingTime(true)
        ProtectedAxios.post("/users/updateChatSharingTime", { user_id: user.user_id, chat_id })
            .then(res => {
                setUpdatingChatSharingTime(false)
                setSharing(res.data.is_sharing)
                setSharedTimestamp(res.data.shared_timestamp)
                toast.success("Sharing latest messages in your chat.")
                onChatDetailsChange(res.data)
            })
            .catch(err => {
                setUpdatingChatSharingTime(false)
                console.log(err);
                toast.error(err.response.data.error)
            })
    }

    const toggleGoogleVisibility = () => {
        setTogglingGoogleVisibility(true)
        ProtectedAxios.post("/users/toggleGoogleVisibility", { user_id: user.user_id, chat_id })
            .then(res => {
                setTogglingGoogleVisibility(false)
                setVisibleOnGoogle(res.data.visible_on_google)
                toast.success(`Your shared chat will be ${res.data.visible_on_google ? "visible on" : "hidden from"} google.`)
                onChatDetailsChange(res.data)
            })
            .catch(err => {
                setTogglingGoogleVisibility(false)
                console.log(err);
                toast.error(err.response.data.error)
            })
    }

    const getIndexingStatus = () => {
        setLoadingIndexingData(true)
        setIndexingData(null)
        ProtectedAxios.get(`/users/getGoogleIndexingStatus?inspectionUrl=${chatLink}`)
            .then(res => {
                setLoadingIndexingData(false)
                setIndexingData(res.data)
                setTimeout(() => {
                    document.getElementById("indexing-data")?.scrollIntoView({ behavior: "smooth" })
                }, 100)
            })
            .catch(err => {
                setLoadingIndexingData(false)
                console.log(err);
                setIndexingData(null)
                toast.error(err.response.data.error)
            })
    }



    return (
        <>
            {!showAsLink
                ?
                <button className={`button-icon position-relative ${className}`} id='share-button' title='share chat' onClick={e => { handleShow() }}>
                    <PiShareFill className='font-s' />
                    {sharing
                        &&
                        <span
                            className="position-absolute p-1 bg-danger border border-light rounded-circle shared-indicator-abs-position"
                        >
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    }
                </button>
                :
                <div className={`${className}`} onClick={handleShow}>
                    {title ? title : "Share Chat"}
                </div>
            }



            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Share Chat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='py-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <h3 className='mb-1 font-s'>
                                    {sharing
                                        ? "This chat is shared"
                                        : "This chat is not shared"
                                    }
                                </h3>
                                <p className='text-secondary m-0 font-xxs'>
                                    {sharing
                                        ? "Toggle the switch to disable chat sharing"
                                        : "Toggle the switch to enable chat sharing"
                                    }
                                </p>
                            </div>
                            <SwitchButton
                                title={sharing ? "Disable chat sharing" : "Share your chat"}
                                loading={updatingChatSharingStatus}
                                active={sharing}
                                onClick={toggleChatSharing}
                            />
                        </div>

                        {sharing
                            &&
                            <div className='mt-4'>
                                <p className='text-secondary'>
                                    Any messages you add after the sharing time will not be shown in the shared chat.
                                    To add the latest message to your shared chat,
                                    &nbsp;
                                    <button className='button-link' disabled={updatingChatSharingTime} onClick={updateChatSharingTimeToLatest}>
                                        click here
                                        {updatingChatSharingTime
                                            &&
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        }
                                    </button>
                                </p>

                                <div className='mt-5 px-3'>
                                    <label htmlFor='chat-link-input' className='d-flex align-items-center gap-1 mb-1'><BiLink className='w-m h-m' /> Chat link</label>
                                    <div className='input-with-icon' id="chat-link-input">
                                        <input value={chatLink} />
                                        <button type='button' className='button-icon edit-btn' title={copied ? 'copied' : 'copy'} value={chatLink} onClick={e => { navigator.clipboard.writeText(e.target.value).then(() => { setCopied(true) }) }}>
                                            {copied
                                                ?
                                                <IoCheckmark className='edit-icon copy-icon text-success' />
                                                :
                                                <IoCopy className='edit-icon copy-icon' />
                                            }
                                        </button>
                                    </div>

                                    <div className='mt-1'>
                                        <div className='d-flex gap-1 justify-content-end align-items-center mb-1'>
                                            <TbWorldShare className='w-m h-m text-secondary' />
                                            <p className='m-0 font-xxs text-secondary'>Anyone can use this link to view your chat</p>
                                        </div>
                                        <p className='font-xxs text-end'><span className="font-xxs text-secondary">sharing time:</span> {formatDate(new Date(sharedTimestamp))}</p>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div>
                                        <button
                                            type='button'
                                            className='d-flex border-0 bg-transparent align-items-center justify-content-between w-100'
                                            onClick={() => setGoogleAccordionState(prev => !prev)}
                                        >
                                            <div className='d-flex w-100 align-items-center gap-3'>
                                                <p className='m-0'>Search Engine Visibility</p>
                                                <span className='w-50 border-top' />
                                            </div>
                                            <GoTriangleUp className={`w-s h-s transition-all ${googleAccordionState ? "rotate-0" : "rotate-180"}`} />
                                        </button>
                                    </div>
                                    <div className={`transition-all overflow-hidden ${googleAccordionState ? "h-auto" : "h-0"}`}>
                                        <div className='d-flex align-items-center gap-2 mt-3'>
                                            {/* <img
                                                src="https://steelbluemedia.com/wp-content/uploads/2019/06/new-google-favicon-512.png"
                                                className='w-m h-m'
                                            /> */}
                                            <p className='m-0 fw-medium'>Show your shared chat on Search Engines</p>
                                        </div>

                                        <p className='mt-2'>With the help of this setting you can choose if your shared chat is shown in search engine's result.</p>
                                        <SwitchButton
                                            title={visibleOnGoogle ? "remove from Google" : "show on Google"}
                                            loading={togglingGoogleVisibility}
                                            active={visibleOnGoogle}
                                            onClick={toggleGoogleVisibility}
                                        />
                                        <p className='text-secondary mt-1 font-xxs'>Changes will need some time to reflect on search engine's result.</p>

                                        <div className="d-flex justify-content-between">
                                            {indexingData
                                                ? <button type='button' className='button-icon edit-btn' title="hide data" onClick={() => setIndexingData(null)}>
                                                    <MdClose className='edit-icon copy-icon' />
                                                </button>

                                                : <span />
                                            }
                                            <button
                                                onClick={getIndexingStatus}
                                                disabled={loadingIndexingData}
                                                className="button-link font-xxs"
                                            >
                                                {!loadingIndexingData
                                                    ? <MdRefresh className="w-m h-m" />
                                                    :
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                }
                                                Check Status
                                            </button>
                                        </div>

                                        {indexingData
                                            &&
                                            <div className="container" id="indexing-data">
                                                <div className="row mt-2 pt-2">
                                                    <div className="col-6 text-break">
                                                        Verdict
                                                    </div>
                                                    <div className="col-6 text-break">
                                                        {indexingData?.verdict ? indexingData.verdict : "-"}
                                                    </div>
                                                </div>

                                                <div className="row mt-2 pt-2 border-top">
                                                    <div className="col-6 text-break">
                                                        Coverage State
                                                    </div>
                                                    <div className="col-6 text-break">
                                                        {indexingData?.coverageState ? indexingData.coverageState : "-"}
                                                    </div>
                                                </div>

                                                <div className="row mt-2 pt-2 border-top">
                                                    <div className="col-6 text-break">
                                                        Page Fetch Status
                                                    </div>
                                                    <div className="col-6 text-break">
                                                        {indexingData?.pageFetchState ? indexingData.pageFetchState : "-"}
                                                    </div>
                                                </div>

                                                <div className="row mt-2 pt-2 border-top">
                                                    <div className="col-6 text-break">
                                                        Last Crawled Time
                                                    </div>
                                                    <div className="col-6 text-break">
                                                        {indexingData?.lastCrawlTime ? formatDate(new Date(indexingData.lastCrawlTime)) : "-"}
                                                    </div>
                                                </div>

                                                <div className="row mt-2 pt-2 border-top">
                                                    <div className="col-6 text-break">
                                                        Crawled As
                                                    </div>
                                                    <div className="col-6 text-break">
                                                        {indexingData?.crawledAs ? indexingData.crawledAs : "-"}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='button' onClick={() => handleClose()}>
                        Done
                    </button>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default ShareChat