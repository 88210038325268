import { useState } from 'react';
import { Dialog } from '../../ui/Dialog';
import ProtectedAxios from '../../../api/protectedAxios';
import toast from 'react-hot-toast';

const ResetInstalledFromMarketplaceTemplateDialog = ({
    selectedTemplate,
    setTemplates,
    user
}) => {
    const [resettingTemplate, setResettingTemplate] = useState(false)
    const [dialogState, setDialogState] = useState(false)

    const resetTemplateToDefaultMarketplaceValues = () => {
        setResettingTemplate(true)
        ProtectedAxios.post("/users/resetTemplate", { user_id: user.user_id, selected_user_id: user?.user_id, template_id: selectedTemplate?.template_id })
            .then(res => {
                setTemplates(prev => {
                    const updatedTemplates = [...prev];
                    const index = updatedTemplates.findIndex(template => template.template_id === selectedTemplate?.template_id);
                    updatedTemplates[index] = { ...updatedTemplates[index], ...res.data };
                    return updatedTemplates;
                })
                setResettingTemplate(false);
                setDialogState(false);
                toast.success("Template reset successfully.")
            })
            .catch(err => {
                console.log(err)
                toast.error("Couldn't reset template at the moment please try again later.")
                setResettingTemplate(false)
            })
    }

    return (
        <>
            <Dialog
                open={dialogState}
                onOpenChange={setDialogState}
                trigger={
                    <div onClick={() => setDialogState(prev => !prev)}>Reset to default</div>
                }
                title="Confirm reset"
                body={
                    <div className='container'>
                        <p className='m-0 fw-semibold'>{selectedTemplate?.name}</p>
                        <p className='my-3'>
                            Resetting this template to its default settings will revert it to the original marketplace configuration. Any custom changes you've made to the template will be lost and cannot be recovered.
                            <br/>
                            Are you sure you want to proceed with the reset?
                        </p>
                    </div>
                }
                footer={
                    <div className='d-flex justify-content-end'>
                        <button className='button button-danger' disabled={resettingTemplate} onClick={() => resetTemplateToDefaultMarketplaceValues(selectedTemplate?.template_id)}>
                            Yes, reset
                            {resettingTemplate
                                &&
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            }
                        </button>
                    </div>
                }
            />
        </>
    )
}

export default ResetInstalledFromMarketplaceTemplateDialog;