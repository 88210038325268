import React, { useContext, useEffect, useState } from 'react'
import NewChat from '../components/NewChat'
import { Helmet } from 'react-helmet'
import ProtectedAxios from '../api/protectedAxios';
import { UserContext } from '../context/UserProvider';
import Settings from '../components/settings/Settings';
import { useNavigate } from 'react-router-dom';
import { MdArrowBack, MdOutlineRefresh } from 'react-icons/md';
import LoadingSkeleton from '../components/LoadingSkeleton';
import { BiSad } from 'react-icons/bi';

const StartChat = ({ updateTokens }) => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();


    // -------------------------------------------------------------------------
    // New Chat
    // -------------------------------------------------------------------------
    const [prompt, setPrompt] = useState("");
    const [templateId, setTemplateId] = useState(null);


    // -------------------------------------------------------------------------
    // Explorer Prompts
    // -------------------------------------------------------------------------
    const [fetchingExplorerPrompts, setFetchingExplorerPrompts] = useState(true);
    const [fetchingMoreExplorerPrompts, setFetchingMoreExplorerPrompts] = useState(false);
    const [explorerPrompts, setExplorerPrompts] = useState([]);
    const [explorerPromptPage, setExplorerPromptPage] = useState(1);

    const loadInitialExplorerPrompts = () => {
        setFetchingExplorerPrompts(true);
        ProtectedAxios.post("/users/getExplorerPrompts", { page: 1 })
            .then(res => {
                if (res.data.length > 0) {
                    setExplorerPrompts(res.data);
                }
                setFetchingExplorerPrompts(false);

            })
            .catch(err => {
                console.log(err);
                setFetchingExplorerPrompts(false);
            })
    }
    const loadMoreExplorerPrompts = () => {
        setFetchingMoreExplorerPrompts(true);
        let updatedPage = explorerPromptPage + 1;
        setExplorerPromptPage(page => page + 1);
        ProtectedAxios.post("/users/getExplorerPrompts", { page: updatedPage })
            .then(res => {
                if (res.data.length < 3 || res.data.length === 0) {
                    setExplorerPromptPage(0);
                }
                if (res.data.length > 0) {
                    setExplorerPrompts(res.data);
                }
                setFetchingMoreExplorerPrompts(false);
            })
            .catch(err => {
                console.log(err);
                setFetchingMoreExplorerPrompts(false);
            })
    }

    useEffect(() => {
        loadInitialExplorerPrompts();
    }, []);


    // -------------------------------------------------------------------------
    // Explorer Prompts
    // -------------------------------------------------------------------------
    const [fetchingJumpstartTemplates, setFetchingJumpstartTemplates] = useState(true);
    const [fetchingMoreJumpstartTemplates, setFetchingMoreJumpstartTemplates] = useState(false);
    const [jumpstartTemplates, setJumpstartTemplates] = useState([]);
    const [jumpstartTemplatePage, setJumpstartTemplatePage] = useState(1);

    const loadInitialJumpstartTemplates = () => {
        setFetchingJumpstartTemplates(true);
        ProtectedAxios.post("/users/getMarketplaceTemplatesForJumpstartSection", { page: 1 })
            .then(res => {
                if (res.data.length > 0) {
                    setJumpstartTemplates(res.data);
                }
                setFetchingJumpstartTemplates(false);

            })
            .catch(err => {
                console.log(err);
                setFetchingJumpstartTemplates(false);
            })
    }
    const loadMoreJumpstartTemplates = () => {
        setFetchingMoreJumpstartTemplates(true);
        let updatedPage = jumpstartTemplatePage + 1;
        setJumpstartTemplatePage(jumpstartTemplatePage => jumpstartTemplatePage + 1);
        ProtectedAxios.post("/users/getMarketplaceTemplatesForJumpstartSection", { page: updatedPage })
            .then(res => {
                if (res.data.length < 3 || res.data.length === 0) {
                    setJumpstartTemplatePage(0);
                }
                if (res.data.length > 0) {
                    setJumpstartTemplates(res.data);
                }
                setFetchingMoreJumpstartTemplates(false);
            })
            .catch(err => {
                console.log(err);
                setFetchingMoreJumpstartTemplates(false);
            })
    }

    useEffect(() => {
        loadInitialJumpstartTemplates();
    }, []);


    return (
        <div className='container py-5'>
            <Helmet>
                <title>New Chat - SageCollab</title>
            </Helmet>

            <div className='new-chat-container'>
                <div className='flexed-between'>
                    <div className="d-flex align-items-center gap-2">
                        <div>
                            <button type="submit" className='button-icon' title='back' onClick={() => navigate(-1)}><MdArrowBack /></button>
                        </div>
                        <h2 className="text-left w-100 title m-0">Start A.I. Chat</h2>
                    </div>
                    {user?.user_id
                        &&
                        <Settings text="Settings" title="chat settings" viewAsLisk={false} />
                    }
                </div>

                <div className='chat-assistance-main'>
                    <div className='chat-assistance-container'>
                        <div className='chat-assistance'>
                            <button className='edit-btn' disabled={fetchingMoreJumpstartTemplates} onClick={() => { loadMoreJumpstartTemplates(); document.getElementById("refresh-jumpstartTemplates-icon").style.transform = `rotate(${360 * jumpstartTemplatePage}deg)` }}>
                                <MdOutlineRefresh
                                    className='w-m h-m'
                                    id="refresh-jumpstartTemplates-icon"
                                />
                            </button>
                            <h4>Jumpstart with jumpstartTemplates</h4>
                            <div className='assistant-items'>
                                {fetchingJumpstartTemplates || fetchingMoreJumpstartTemplates
                                    ?
                                    <>
                                        <LoadingSkeleton type='tags assistant-item' />
                                        <LoadingSkeleton type='tags assistant-item' />
                                        <LoadingSkeleton type='tags assistant-item' />
                                    </>
                                    :
                                    <>
                                        {jumpstartTemplates.length > 0
                                            ?
                                            jumpstartTemplates.map((template, i) => {
                                                return (
                                                    <div key={i} className='assistant-item' title={template.name} onClick={() => { setTemplateId(template.template_id) }}><span className='font-xxs text-truncate'>{template.name}</span></div>
                                                )
                                            })

                                            :
                                            <>
                                                <BiSad className='w-xl h-xl mb-2 mt-3' style={{ color: 'var()' }} />
                                                <p className='subtitle'>Nothing to show here at the moment</p>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>

                        <div className='chat-assistance'>
                            <button className='edit-btn' disabled={fetchingMoreExplorerPrompts} onClick={() => { loadMoreExplorerPrompts(); document.getElementById("refresh-explorerPrompts-icon").style.transform = `rotate(${360 * explorerPromptPage}deg)` }}>
                                <MdOutlineRefresh
                                    className='w-m h-m'
                                    id="refresh-explorerPrompts-icon"
                                />
                            </button>
                            <h4>Explore</h4>
                            <div className='assistant-items'>
                                {fetchingExplorerPrompts || fetchingMoreExplorerPrompts
                                    ?
                                    <>
                                        <LoadingSkeleton type='tags assistant-item' />
                                        <LoadingSkeleton type='tags assistant-item' />
                                        <LoadingSkeleton type='tags assistant-item' />
                                    </>
                                    :
                                    <>
                                        {explorerPrompts.length > 0
                                            ?
                                            explorerPrompts.map((prompt, i) => {
                                                return (
                                                    <div key={i} className='assistant-item' title={prompt.prompt_title} onClick={() => setPrompt(prompt.prompt_text)}><span className='font-xxs text-truncate'>{prompt.prompt_title}</span></div>
                                                )
                                            })

                                            :
                                            <>
                                                <BiSad className='w-xl h-xl mb-2 mt-3' style={{ color: 'var()' }} />
                                                <p className='subtitle'>Nothing to show here at the moment</p>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <NewChat
                    prompt={prompt}
                    setPrompt={setPrompt}
                    templateId={templateId}
                    setTemplateId={setTemplateId}
                    updateTokens={updateTokens}
                />
            </div>
        </div>
    )
}

export default StartChat