import React, { useContext, useState } from 'react'
import { UserContext } from '../context/UserProvider'
import { MdEdit } from 'react-icons/md'
import { RiCloseLine } from 'react-icons/ri'
import ProtectedAxios from '../api/protectedAxios'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import AddOpenaiAPIkeyModal from './AddOpenaiAPIkeyModal'
import { toast } from 'react-hot-toast'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Fade } from 'react-bootstrap'
import useSubscriptionDetails from '../hooks/useSubscriptionDetails'
import { formatDateWithDay } from '../utils/helper'
import { useLocation, NavLink } from 'react-router-dom'
import AddGeminiAPIkeyModal from './AddGeminiAPIkeyModal'
import AddAzureOpenaiKeysModal from './AddAzureOpenaiKeysModal'

const UpdateProfileForm = () => {
    const [user, setUser] = useContext(UserContext)
    const [viewingOpenaiKey, setViewingOpenaiKey] = useState(false)
    const [viewingGeminiKey, setViewingGeminiKey] = useState(false)

    const [showOpenaiApiKeyModal, setShowOpenaiApiKeyModal] = useState(false);
    const [showGeminiApiKeyModal, setShowGeminiApiKeyModal] = useState(false);
    const [showAzureKeysModal, setShowAzureKeysModal] = useState(false);

    const [subscriptionDetails, refreshSubscriptionDetails] = useSubscriptionDetails()
    const [loadingSubscriptionDetails, setLoadingSubscriptionDetails] = useState(true)

    const [creatingCustomerPortal, setCreatingCustomerPortal] = useState(false)
    let { pathname } = useLocation()
    const [downgradingToFreePlan, setDowngradingToFreePlan] = useState(false)
    const [renewingStandardPlan, setRenewingStandardPlan] = useState(false)


    const [editingData, setEditingData] = useState({
        isEditing: false,
        editingField: '',
        newValue: '',
        oldValue: '',
        isLoading: false
    })
    const [error, setError] = useState({
        errorField: '',
        errorMsg: ''
    })

    const nameRegex = /^[a-zA-Z\s]*$/;

    const updateProfileData = e => {
        e.preventDefault()
        setError({ errorField: '', errorMsg: '' })

        setEditingData({ ...editingData, isLoading: true })
        if (editingData.editingField === "name" && !nameRegex.test(editingData.newValue)) {
            setError({ errorField: 'name', errorMsg: 'name can only contain letters' })
            setEditingData({ ...editingData, isLoading: false })

        } else {
            ProtectedAxios.post('/users/updateProfile', { user_id: user.user_id, editingField: editingData.editingField, newValue: editingData.newValue, oldValue: editingData.oldValue })
                .then(res => {
                    toast.success(`${editingData.editingField} updated`)
                    if (editingData.editingField === 'password') {
                        setEditingData({ ...editingData, isLoading: false, editingField: '', newValue: '', oldValue: '' })
                    } else {
                        setUser({ ...user, [res.data.updatedFieldName]: editingData.newValue })
                        setEditingData({ ...editingData, isLoading: false, editingField: '', newValue: '', oldValue: '' })
                    }
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        setError({ errorField: err.response.data.fieldName, errorMsg: err.response.data.error })
                    } else {
                        toast.error(err.response.data.error)
                    }
                    setEditingData({ ...editingData, isLoading: false })
                })
        }
    }

    const createPortalSession = () => {
        setCreatingCustomerPortal(true)
        ProtectedAxios.post('/subscription/create-portal-session', { customer_id: user.stripe_customer_id, current_path: pathname })
            .then(res => {
                window.location.href = res.data
                setCreatingCustomerPortal(false)
            })
            .catch(err => {
                setCreatingCustomerPortal(false)
                if (err.response.status === 500) {
                    // toast.error(err.response.data.error)
                    toast.error("Can't create subscription management session at the moment, please try again later")
                }
            })
    }

    const downgradeToFreePlan = () => {
        setDowngradingToFreePlan(true)
        ProtectedAxios.post('/subscription/downgradeToFreePlan', { customer_id: user.stripe_customer_id })
            .then(res => {
                if (res.data) {
                    refreshSubscriptionDetails()
                    toast("You will be downgraded to Free plan at the end of your billing cycle", {
                        icon: "🔻"
                    })
                    setDowngradingToFreePlan(false)
                }
            })
            .catch(err => {
                setDowngradingToFreePlan(false)
                if (err.response.status === 500) {
                    // toast.error(err.response.data.error)
                    toast.error("Can't downgrade to Free plan at the moment, please try again later")
                }
            })
    }
    const renewStandardPlan = () => {
        setRenewingStandardPlan(true)
        ProtectedAxios.post('/subscription/renewStandardPlan', { customer_id: user.stripe_customer_id })
            .then(res => {
                if (res.data) {
                    refreshSubscriptionDetails()
                    toast("Your Standard plan is renewed", {
                        icon: "🔃"
                    })
                    setRenewingStandardPlan(false)
                }
            })
            .catch(err => {
                setRenewingStandardPlan(false)
                if (err.response.status === 500) {
                    // toast.error(err.response.data.error)
                    toast.error("Can't renew your Standard plan at the moment, please try again later")
                }
            })
    }

    return (
        <Tabs
            defaultActiveKey="Profile"
            id="profile-tabs"
            className="mb-3 mt-5 profile-tabs"
            transition={Fade}
            justify
        >
            <Tab eventKey="Profile" title="Profile">
                <section className='update-profile-form-container'>
                    <form onSubmit={updateProfileData}>
                        <div className={`profile-item ${editingData.editingField !== 'name' && 'editing'}`}>
                            <div className='profile-item-header'>
                                <div className='input-grp'>
                                    <label>Full Name</label>
                                    <input type='text' required id='name' value={editingData.editingField === 'name' ? editingData.newValue : user.name} onChange={e => setEditingData({ ...editingData, newValue: e.target.value })} className={editingData.editingField !== 'name' && 'events-none'} />
                                </div>
                                <div className='edit-btn-container'>
                                    {editingData.editingField === 'name'
                                        ?
                                        <div>
                                            <button type='button' disabled={editingData.isLoading} className='button-icon' title='cancel' onClick={() => { setEditingData({ ...editingData, editingField: '', newValue: '' }); setError({ errorField: '', errorMsg: '' }) }}><RiCloseLine className='font-m reject-icon' /></button>
                                        </div>

                                        :
                                        <div>
                                            <button type='button' disabled={editingData.isLoading} className='button-icon' title='update name' onClick={() => { setEditingData({ ...editingData, editingField: 'name', newValue: user.name }); setError({ errorField: '', errorMsg: '' }) }}><MdEdit /></button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='profile-item-footer'>
                                <p className='text-danger'>{error.errorField === 'name' && error.errorMsg}</p>
                                <button type='submit' className="button button-main" disabled={(editingData.editingField === 'name' && editingData.isLoading)}>
                                    {(editingData.editingField === 'name' && editingData.isLoading)
                                        ? <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>

                                        : "Update"
                                    }
                                </button>
                            </div>
                        </div>
                    </form>

                    <form onSubmit={updateProfileData}>
                        <div className={`profile-item ${editingData.editingField !== 'email' && 'editing'}`}>
                            <div className='profile-item-header'>
                                <div className='input-grp'>
                                    <label>Email</label>
                                    <input required type='email' id='email' value={editingData.editingField === 'email' ? editingData.newValue : user.email} onChange={e => setEditingData({ ...editingData, newValue: e.target.value })} className={editingData.editingField !== 'email' && 'events-none'} />
                                </div>
                                <div className='edit-btn-container'>
                                    {editingData.editingField === 'email'
                                        ?
                                        <div>
                                            <button type='button' disabled={editingData.isLoading} className='button-icon' title='cancel' onClick={() => { setEditingData({ ...editingData, editingField: '', newValue: '' }); setError({ errorField: '', errorMsg: '' }) }}><RiCloseLine className='font-m reject-icon' /></button>
                                        </div>

                                        :
                                        <div>
                                            <button type='button' disabled={true} className='button-icon' title='cannot update email' onClick={() => { setEditingData({ ...editingData, editingField: 'email', newValue: user.email }); setError({ errorField: '', errorMsg: '' }) }}><MdEdit /></button>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* <div className='profile-item-footer'>
                        <p className='text-danger'>{error.errorField === 'email' && error.errorMsg}</p>
                        <button type='submit' className="button button-main" disabled={(editingData.editingField === 'email' && editingData.isLoading)}>
                            {(editingData.editingField === 'email' && editingData.isLoading)
                                ? <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>

                                : "Update"
                            }
                        </button>
                    </div> */}
                        </div>
                    </form>

                    <form onSubmit={updateProfileData}>
                        <div className={`profile-item ${editingData.editingField !== 'username' && 'editing'}`}>
                            <div className='profile-item-header'>
                                <div className='input-grp'>
                                    <label>Username</label>
                                    <input type='username' required id='username' value={editingData.editingField === 'username' ? editingData.newValue : user.username} onChange={e => setEditingData({ ...editingData, newValue: e.target.value })} className={editingData.editingField !== 'username' && 'events-none'} />
                                </div>
                                <div className='edit-btn-container'>
                                    {editingData.editingField === 'username'
                                        ?
                                        <div>
                                            <button type='button' disabled={editingData.isLoading} className='button-icon' title='cancel' onClick={() => { setEditingData({ ...editingData, editingField: '', newValue: '' }); setError({ errorField: '', errorMsg: '' }) }}><RiCloseLine className='font-m reject-icon' /></button>
                                        </div>

                                        :
                                        <div>
                                            <button type='button' disabled={editingData.isLoading} className='button-icon' title='update username' onClick={() => { setEditingData({ ...editingData, editingField: 'username', newValue: user.username }); setError({ errorField: '', errorMsg: '' }) }}><MdEdit /></button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='profile-item-footer'>
                                <p className='text-danger'>{error.errorField === 'username' && error.errorMsg}</p>
                                <button type='submit' className="button button-main" disabled={(editingData.editingField === 'username' && editingData.isLoading)}>
                                    {(editingData.editingField === 'username' && editingData.isLoading)
                                        ? <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>

                                        : "Update"
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                    {user.role_id !== 5 && user.role_id !== 1 && user.stripe_subscription_id !== undefined
                        &&
                        <form onSubmit={updateProfileData}>
                            <div className={`profile-item editing`}>
                                <div className='profile-item-header'>
                                    <div className='input-grp'>
                                        <label>OpenAI API Key</label>
                                        <div className='input-with-icon'>
                                            <input required type={viewingOpenaiKey ? 'text' : 'password'} id='key' value={user?.openai_api_key} placeholder={!user?.openai_api_key && 'No key found (edit to add key)'} className='events-none' />
                                            {user?.openai_api_key
                                                &&
                                                <>
                                                    {viewingOpenaiKey
                                                        ?
                                                        <button type="button" className='button-icon edit-btn' title='hide key' onClick={() => { setViewingOpenaiKey(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                                        :
                                                        <button type="button" className='button-icon edit-btn' title='view key' onClick={() => { setViewingOpenaiKey(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className='edit-btn-container'>
                                        <div>
                                            <button type='button' disabled={editingData.isLoading} className='button-icon' title='Add/Update OpenAI API Key' onClick={() => setShowOpenaiApiKeyModal(true)}><MdEdit className='' /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                    {user.role_id !== 5 && user.role_id !== 1 && user.stripe_subscription_id !== undefined
                        &&
                        <form onSubmit={updateProfileData}>
                            <div className={`profile-item editing`}>
                                <div className='profile-item-header'>
                                    <div className='input-grp'>
                                        <label>Gemini API Key</label>
                                        <div className='input-with-icon'>
                                            <input required type={viewingGeminiKey ? 'text' : 'password'} id='key' value={user?.gemini_api_key} placeholder={!user?.gemini_api_key && 'No key found (edit to add key)'} className='events-none' />
                                            {user?.gemini_api_key
                                                &&
                                                <>
                                                    {viewingGeminiKey
                                                        ?
                                                        <button type="button" className='button-icon edit-btn' title='hide key' onClick={() => { setViewingGeminiKey(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                                        :
                                                        <button type="button" className='button-icon edit-btn' title='view key' onClick={() => { setViewingGeminiKey(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className='edit-btn-container'>
                                        <div>
                                            <button type='button' disabled={editingData.isLoading} className='button-icon' title='Add/Update Gemini API Key' onClick={() => setShowGeminiApiKeyModal(true)}><MdEdit className='' /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }

                    {(user.role_id === 4) && user.stripe_subscription_id !== undefined
                        &&
                        <form onSubmit={updateProfileData}>
                            <div className={`profile-item editing`}>
                                <div className='profile-item-header'>
                                    <div className='input-grp'>
                                        <label>Azure OpenAI Keys</label>
                                        <div className='d-flex align-items-center gap-1'>
                                            <span className='m-0'>Manage</span>
                                            <div className='edit-btn-container p-0'>
                                                <button type='button' disabled={editingData.isLoading} className='edit-btn' title='Add/Update Azure OpenAI Key' onClick={() => setShowAzureKeysModal(true)}><MdEdit className='edit-icon ' /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }

                    {/* <form onSubmit={updateProfileData}>
                <div className={`profile-item textarea-item ${editingData.editingField === 'address' && 'editing'}`}>
                    <div className='profile-item-header'>
                        <div className='input-grp'>
                            <label>Address</label>
                            <textarea type='text' required id='address' value={editingData.editingField === 'address' ? editingData.newValue : user.address} onChange={e => setEditingData({ ...editingData, newValue: e.target.value })} className={editingData.editingField !== 'address' && 'events-none'} />
                        </div>
                        <div className='edit-btn-container'>
                            {editingData.editingField === 'address'
                                ?
                                <button type='button' disabled={editingData.isLoading} className='edit-btn' title='cancel' onClick={() => { setEditingData({ ...editingData, editingField: '', newValue: '' }); setError({ errorField: '', errorMsg: '' }) }}><RiCloseLine className='edit-icon reject-icon' /></button>

                                :
                                <button type='button' disabled={editingData.isLoading} className='edit-btn' title='update address' onClick={() => { setEditingData({ ...editingData, editingField: 'address', newValue: user.address }); setError({ errorField: '', errorMsg: '' }) }}><MdEdit className='edit-icon ' /></button>
                            }
                        </div>
                    </div>
                    <div className='profile-item-footer'>
                        <p className='text-danger'>{error.errorField === 'address' && error.errorMsg}</p>
                        <button type='submit' className="button button-main" disabled={(editingData.editingField === 'address' && editingData.isLoading)}>
                            {(editingData.editingField === 'address' && editingData.isLoading)
                                ? <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>

                                : "Update"
                            }
                        </button>
                    </div>
                </div>
            </form> */}


                    <form onSubmit={updateProfileData}>
                        <div className={`profile-item password-item ${editingData.editingField === 'password' && 'editing'}`}>
                            <div className='profile-item-header'>
                                <div className='input-grp'>
                                    <label>Update Password</label>
                                    <button type='button' disabled={editingData.isLoading} title='update password' onClick={() => { setEditingData({ ...editingData, editingField: 'password', newValue: '' }) }} className='button-plain-text pe-0 gap-2'>I want to change my password <div className='button-icon'><MdEdit className='' /></div></button>
                                    <br />
                                    <label>Current Password</label>
                                    <input type='password' required id='password' value={editingData.editingField === 'password' && editingData.oldValue} onChange={e => setEditingData({ ...editingData, oldValue: e.target.value })} className={editingData.editingField !== 'password' && 'events-none'} />
                                    <br />
                                    <label>New Password</label>
                                    <input type='password' required id='confirm-password' value={editingData.editingField === 'password' && editingData.newValue} onChange={e => setEditingData({ ...editingData, newValue: e.target.value })} className={editingData.editingField !== 'password' && 'events-none'} />
                                </div>
                                <div className='edit-btn-container'>
                                    {editingData.editingField === 'password'
                                        ?
                                        <div>
                                            <button type='button' disabled={editingData.isLoading} className='button-icon' title='cancel' onClick={() => { setEditingData({ ...editingData, editingField: '', newValue: '', oldValue: '' }); setError({ errorField: '', errorMsg: '' }) }}><RiCloseLine className='font-m reject-icon' /></button>
                                        </div>
                                        :
                                        <button type='button' disabled={editingData.isLoading} className='button-icon' title='update password' onClick={() => { setEditingData({ ...editingData, editingField: 'password', newValue: user.password }); setError({ errorField: '', errorMsg: '' }) }}><MdEdit className='' /></button>
                                    }
                                </div>
                            </div>
                            <div className='profile-item-footer'>
                                <p className='text-danger'>{error.errorField === 'password' && error.errorMsg}</p>
                                <button type='submit' className="button button-main" disabled={(editingData.editingField === 'password' && editingData.isLoading)}>
                                    {(editingData.editingField === 'password' && editingData.isLoading)
                                        ? <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>

                                        : "Update"
                                    }
                                </button>
                            </div>
                        </div>
                    </form>


                    <AddOpenaiAPIkeyModal
                        show={showOpenaiApiKeyModal}
                        onHide={() => setShowOpenaiApiKeyModal(false)}
                        heading='Add/Edit API Key'
                        apiKey={user?.openai_api_key}
                        title='Add/Edit your OpenAI API key'
                    />

                    <AddGeminiAPIkeyModal
                        show={showGeminiApiKeyModal}
                        onHide={() => setShowGeminiApiKeyModal(false)}
                        heading='Add/Edit API Key'
                        apiKey={user?.gemini_api_key}
                        title='Add/Edit your Gemini API key'
                    />

                    <AddAzureOpenaiKeysModal
                        show={showAzureKeysModal}
                        onHide={() => setShowAzureKeysModal(false)}
                        heading="Manage Azure Keys"
                        title='Manage your Azure OpenAI keys'
                    />

                </section >
            </Tab>


            <Tab eventKey="Subscription" title="Subscription">
                {user.stripe_subscription_id === undefined
                    ?
                    <section className='centered-flex py-5 my-5'>
                        <p>You don't have any active subscriptions at the moment</p>
                    </section>
                    :
                    <>
                        {subscriptionDetails?.price_id === undefined
                            ?
                            <section className='centered-flex py-5 my-5'>
                                <div className="my-5 spinner-border spinner-border-md" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </section>
                            :
                            <section className='subscription-details-container'>
                                <div>
                                    <h4 className={`d-inline ${subscriptionDetails.cancel_at_period_end ? 'opacity-75' : ''}`}>
                                        {subscriptionDetails?.price_id === process.env.REACT_APP_PRICE_A_ID
                                            ? 'Free Plan'
                                            :
                                            <>
                                                {
                                                    subscriptionDetails?.price_id === process.env.REACT_APP_PRICE_B_ID
                                                        ? 'Standard Plan'
                                                        : 'Enterprise Plan'
                                                }
                                            </>
                                        }
                                    </h4>
                                    {subscriptionDetails.metadata?.isUpgradeByAdmin
                                        &&
                                        <span className='trial'>on trial</span>
                                    }
                                    {subscriptionDetails.cancel_at_period_end
                                        &&
                                        <span className='cancelled'>canclled</span>
                                    }
                                </div>
                                <div className='subscription-detail-body'>
                                    <p>Status: {subscriptionDetails.cancel_at_period_end ? "Cancelled" : "Active"}</p>
                                    <p>
                                        {subscriptionDetails.metadata?.isUpgradeByAdmin ? "Trial ends on" : subscriptionDetails.cancel_at_period_end ? "Downgrades to Free plan on" : "Renews on"}:
                                        &nbsp;{formatDateWithDay(new Date(subscriptionDetails.metadata?.isUpgradeByAdmin ? subscriptionDetails.metadata?.endDate * 1000 : subscriptionDetails.current_period_end * 1000))}
                                        {/* {subscriptionDetails.cancel_at_period_end
                                            &&
                                            <button type='submit' className="button button-plain-text" onClick={() => createPortalSession()} disabled={creatingCustomerPortal}>
                                                {creatingCustomerPortal
                                                    ?
                                                    <>
                                                        manage
                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                    </>

                                                    : "manage"
                                                }
                                            </button>
                                        } */}
                                    </p>
                                </div>
                                <div className='btn-container btn-row-container align-items-start'>
                                    {subscriptionDetails.price_id === process.env.REACT_APP_PRICE_A_ID
                                        &&
                                        <NavLink to="/upgrade" className="button button-primary" >
                                            Upgrade
                                        </NavLink>
                                    }
                                    {subscriptionDetails.price_id === process.env.REACT_APP_PRICE_B_ID && subscriptionDetails.cancel_at_period_end === false
                                        &&
                                        <button type='submit' className="button button-danger" onClick={() => downgradeToFreePlan()} disabled={downgradingToFreePlan}>
                                            {downgradingToFreePlan
                                                ?
                                                <>
                                                    Downgrade
                                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </>

                                                : "Downgrade"
                                            }
                                        </button>
                                    }
                                    {subscriptionDetails.price_id === process.env.REACT_APP_PRICE_B_ID && subscriptionDetails.cancel_at_period_end === true
                                        &&
                                        <button type='submit' className="button" onClick={() => renewStandardPlan()} disabled={renewingStandardPlan}>
                                            {renewingStandardPlan
                                                ?
                                                <>
                                                    Renew Again
                                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </>

                                                : "Renew Again"
                                            }
                                        </button>
                                    }
                                </div>
                            </section>
                        }
                    </>
                }
            </Tab>
        </Tabs>

    )
}

export default UpdateProfileForm