import React, { Children, useState, useEffect, useRef, useContext } from 'react'
import { GoKebabHorizontal } from 'react-icons/go'
import { EventContext } from '../context/EventProvider';

const MyDropdown = ({ children, m0 = false, position = "right", style, dropdownButton, dropdownIcon, title, disabled, className }) => {
    const { eventData, emitEvent } = useContext(EventContext);
    const [show, setShow] = useState(false)
    const dropdownElementRef = useRef(null)
    useClickOutside(dropdownElementRef)

    function useClickOutside(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShow(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        if (eventData?.eventName === "closeChatDropdown") {
            setShow(false)
        }
    }, [eventData])

    return (
        <div ref={dropdownElementRef} className={`my-dropdown ${show ? 'show' : ''} ${className}`} onClick={() => { setShow(prev => !prev) }}>
            {dropdownButton
                ? dropdownButton

                :
                <button disabled={disabled} type="button" className="edit-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {dropdownIcon
                        ? dropdownIcon
                        :
                        <GoKebabHorizontal className='edit-icon copy-icon'
                            style={{ transform: 'rotate(90deg)' }}
                        />
                    }
                </button>
            }
            <div className={`dropdown-menu container ${show ? 'show' : ''} ${m0 ? 'm-0' : 'margin-top-2-5'} ${position}`} style={{ ...style }}>
                <div className='dropdown-title'>
                    {title}
                </div>
                <div className='dropdown-menu-content'>
                    {children}
                </div>
            </div>
        </div >
    )
}

export default MyDropdown