import { useEffect, useRef, useState } from "react"
import ProtectedAxios from "../../api/protectedAxios"
import toast from "react-hot-toast"
import { GrFormAdd, GrFormClose } from "react-icons/gr"

const CategorySelector = ({ user, selectedTemplate, setSelectedTemplate }) => {
    const categoryDropdownRef = useRef(null)
    const [showingCategoryDropdown, setShowingCategoryDropdown] = useState(true)
    const [categorySearchTerm, setCategorySearchTerm] = useState("")
    const [fetchingCategories, setFetchingCategories] = useState(true)
    const [categories, setCategories] = useState([])
    const [addingNewCategory, setAddingNewCategory] = useState(false)
    useClickOutside(categoryDropdownRef)
    function useClickOutside(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowingCategoryDropdown(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const handleCategoryChange = e => {
        setCategorySearchTerm(e.target.value)
        let searchedTerm = e.target.value
        setFetchingCategories(true)
        ProtectedAxios.post("/users/getCategories", { searchedTerm })
            .then(res => {
                setFetchingCategories(false)
                setCategories(res.data)
            })
            .catch(err => {
                setFetchingCategories(false)
                console.log(err)
                toast.error(err.response.data.error)
            })
    }
    const addNewCategory = () => {
        setAddingNewCategory(true)
        ProtectedAxios.post("/users/createNewCategory", { user_id: user.user_id, selected_user_id: user.user_id, searchedTerm: categorySearchTerm })
            .then(res => {
                if (res.data) {
                    // setTemplateData(prev => { return { ...prev, selectedCategories: prev.selectedCategories.push(res.data) } })
                    selectCategory(res.data)
                    setCategorySearchTerm("")
                    setAddingNewCategory(false)
                }
            })
            .catch(err => {
                console.log(err)
                setAddingNewCategory(false)
                toast.error(err.response.data.error)
            })
    }
    const selectCategory = (categoryData) => {
        //check if category is already added
        let index = selectedTemplate.selectedCategories.findIndex(category => category.category === categoryData.category)
        if (index === -1) {
            setSelectedTemplate(prev => {
                let updatedCategories = [...prev.selectedCategories]
                updatedCategories.push(categoryData)
                return { ...prev, selectedCategories: updatedCategories }
            })
        }
    }
    const removeCategory = (categoryData) => {
        const updatedCategories = selectedTemplate.selectedCategories.filter(category => category.category_id !== categoryData.category_id)
        setSelectedTemplate(prev => { return { ...prev, selectedCategories: updatedCategories } })

    }

    return (
        <div>
            <div className='input-grp template-grp'>
                <label htmlFor="category-select">Add Category</label>
                <div className='tag-input-container full' style={{ display: 'flex' }}>
                    <input type="text" id="category-select" value={categorySearchTerm} onChange={handleCategoryChange} />
                    <button type='button' className='edit-btn' title='add tag' disabled={addingNewCategory || categorySearchTerm.length <= 0} onClick={() => addNewCategory()}>
                        {addingNewCategory
                            ?
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only"></span>
                            </div>
                            :
                            <GrFormAdd className='edit-icon accept-icon ' />
                        }
                    </button>
                </div>
                {categorySearchTerm.length > 0 && showingCategoryDropdown
                    &&
                    <div ref={categoryDropdownRef} className='list-dropdown md'>
                        {fetchingCategories
                            ?
                            <div className='py-3 opacity-50'>
                                <div className="d-flex m-auto spinner-border spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>

                            :
                            <div className='list-container'>
                                {categories.length === 0
                                    ?
                                    <p className='text-secondary text-center my-3 px-2 font-xxs'>No cateogry found, please complete the name and click + icon to add this category</p>

                                    :
                                    <>
                                        {categories.map((category, i) => {
                                            return (
                                                <div className='list-item d-flex flex-wrap gap-3' key={i}
                                                    onClick={() => {
                                                        selectCategory(category)
                                                        setCategorySearchTerm("")
                                                    }
                                                    }
                                                >
                                                    {category.category.toLowerCase()}
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
            <div className='selected-tag-container'>
                {selectedTemplate.selectedCategories?.length > 0
                    ?
                    <>
                        {selectedTemplate.selectedCategories.map((category, i) => {
                            return (
                                <span key={i} className={`tag tag-color-${i % 4 + 1} editing`}>{category.category.toLowerCase()} <button type='button' title='remove category' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { removeCategory(category) }}><GrFormClose className={`edit-icon`} /></button></span>
                            )
                        })}
                    </>
                    :
                    <span className='subtitle' style={{ marginTop: "-0.7rem" }}>no category selected</span>
                }
            </div>
        </div>
    );
}

export default CategorySelector;