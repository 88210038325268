import { Dialog } from "../../ui/Dialog";

const ConfirmTemplateBuilderClose = ({ open, onOpenChange, message, hideTemplateBuilderCloseConfirmationDialog, handleConfirmCloseTemplateBuilder }) => {
    return (
        <Dialog
            open={open}
            onOpenChange={onOpenChange}
            hideTrigger
            title="Confirm"
            closeButtonFn={hideTemplateBuilderCloseConfirmationDialog}
            body={
                <div>
                    {message
                        ? message
                        : "Are you sure you want to close template builder?"
                    }
                    <div className="d-flex justify-content-end align-items-center mt-4 mb-3">
                        <button className="button-plain-text me-3" onClick={hideTemplateBuilderCloseConfirmationDialog}>Cancel</button>
                        <button className="button button-danger" onClick={handleConfirmCloseTemplateBuilder}>Yes, close</button>
                    </div>
                </div>
            }
            hideFooter
        />
    )
}

export default ConfirmTemplateBuilderClose;