import React from 'react'
import NewChat from '../components/NewChat'
import { Helmet } from 'react-helmet'

const StartChat = ({ tokens, updateTokens }) => {
    return (
        <div className='container py-5'>
            <Helmet>
                <title>New Chat - SageCollab</title>
            </Helmet>
            
            <NewChat updateTokens={updateTokens} tokens={tokens} />
        </div>
    )
}

export default StartChat