import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import ProtectedAxios from '../api/protectedAxios'
import { BsFillHandThumbsUpFill, BsHandThumbsDownFill, BsHandThumbsUp, BsHandThumbsDown, BsArrowDownShort, BsArrowLeftShort } from 'react-icons/bs'
import Tags from '../components/Tags'
import { GoKebabHorizontal } from 'react-icons/go'
import { toast } from 'react-hot-toast'
import ManageChatAccess from '../components/ManageChatAccess'
import { UserContext } from '../context/UserProvider'
import { formatDate, formatDateWithDay } from '../utils/helper'
import Dropdown from 'react-bootstrap/Dropdown';
import placeholderProfile from "../assets/placeholder-profile.jpg"
import MyDropdown from '../components/MyDropdown'
import RenameChat from '../components/RenameChat'
import Messages from '../components/Messages'
import NewMessage from '../components/NewMessage'
import ManageTags from '../components/ManageTags'
import DeleteChat from '../components/DeleteChat'
import ShareChat from '../components/ShareChat'
import { HiOutlineTemplate } from 'react-icons/hi'
import { Helmet } from 'react-helmet'
import CustomModal from '../components/modals/CustomModal'
import AdModal from '../components/AdModal'
import MaxAiInteractionsReachedModal from '../components/modals/MaxAiInteractionsReachedModal'

const Chat = ({ updateTokens, isViewingWithSharedAccess = false, isEnterpriseAdmin = false, tokens }) => {
    const [user] = useContext(UserContext)

    const { chat_id } = useParams()
    const [chatDetails, setChatDetails] = useState('')
    const [loading, setLoading] = useState(true)

    const [messages, setMessages] = useState([])

    const [refresh, setRefresh] = useState(false)

    const [showingChatHeader, setShowingChatHeader] = useState(false)

    const [downloadingPdf, setDownloadingPdf] = useState(false)
    const chatThreadRef = useRef(null)

    const navigate = useNavigate()

    const [refreshTags, setRefreshTags] = useState(false)

    const [pageEnded, setPageEnded] = useState(false)

    const [showMaxInteractionsReachedModel, setShowMaxInteractionsReachedModal] = useState(false)
    const [showAdModal, setShowAdModal] = useState(false)

    const downloadPdf = () => {
        setDownloadingPdf(true)
        document.getElementById("pdf-header").style.display = 'flex'

        if (document.getElementById("prompt-header")) {
            document.getElementById("prompt-header").style.display = 'none'
        }
        if (document.getElementById("pdf-button")) {
            document.getElementById("pdf-button").style.display = 'none'
        }
        if (document.getElementById("share-button")) {
            document.getElementById("share-button").style.display = 'none'
        }
        if (document.getElementById("add-message-form")) {
            document.getElementById("add-message-form").style.display = 'none'
        }
        if (document.querySelectorAll(".remove-tag-button").length) {
            document.querySelectorAll(".remove-tag-button").forEach(el => {
                el.style.transform = 'scale(0)'
            })
        }
        if (document.getElementById("edit-tags-button")) {
            document.getElementById("edit-tags-button").style.display = 'none'
        }
        if (document.getElementById("add-tags-button")) {
            document.getElementById("add-tags-button").style.display = 'none'
        }
        if (document.querySelectorAll(".copy-button").length) {
            document.querySelectorAll(".copy-button").forEach(el => {
                el.style.display = 'none'
            })
        }
        if (document.querySelectorAll(".copy-btn").length) {
            document.querySelectorAll(".copy-btn").forEach(el => {
                el.style.display = 'none'
            })
        }
        if (document.querySelectorAll(".add-note-button").length) {
            document.querySelectorAll(".add-note-button").forEach(el => {
                el.style.display = 'none'
            })
        }
        if (document.getElementById("regenerate-response-button")) {
            document.getElementById("regenerate-response-button").style.display = 'none'
        }
        if (document.getElementById("add-reply-button")) {
            document.getElementById("add-reply-button").style.display = 'none'
        }
        if (document.getElementById("add-reply-form")) {
            document.getElementById("add-reply-form").style.display = 'none'
        }
        if (document.querySelectorAll(".hidden-for-pdf").length) {
            document.querySelectorAll(".hidden-for-pdf").forEach(el => {
                el.style.display = 'none'
            })
        }
        if (document.querySelectorAll(".note-form").length) {
            document.querySelectorAll(".note-form").forEach(el => {
                el.style.display = 'none'
            })
        }
        if (document.getElementById("tag-form")) {
            document.getElementById("tag-form").style.display = 'none'
        }
        if (document.getElementById("scroll-down-button")) {
            document.getElementById("scroll-down-button").style.display = 'none'
        }
        if (document.querySelectorAll(".regenerate-options").length) {
            document.querySelectorAll(".regenerate-options").forEach(el => {
                el.style.display = 'none'
            })
        }
        if (document.querySelectorAll(".response-header button").length) {
            document.querySelectorAll(".response-header button").forEach(el => {
                el.style.display = 'none'
            })
        }
        if (document.querySelectorAll(".pdf-hidden").length) {
            document.querySelectorAll(".pdf-hidden").forEach(el => {
                el.style.display = 'none'
            })
        }
        document.getElementById("chat-options-toggle").style.display = 'none'


        let html = `
        <!DOCTYPE html>
        <html>
        <head>
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            
            @import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,600;1,700;1,900&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        

            :root {
                --bg-body: rgba(255, 255, 255);
                --bg-nav: rgb(255, 255, 255);
            
                --clr-main: #00BFA5;
                --clr-main-hover: #00d3b7;
                --clr-sec: #252525;
                --clr-sec-hover: #3d3d3d;
                --clr-tert: #FF8E53;
            
                --text-main: #222;
                --text-main-hover: #5e5e5e;
            
                --text-sec: rgb(114, 114, 114);
            
                --fs-1: 28px;
                --fs-2: 24px;
                --fs-3: 18px;
                --fs-4: 16px;
                --fs-5: 14px;
                --fs-6: 8px;
                
                --clr-token: #ebc90c;
            
                --nav-height: 5rem;
            }

            .container {
                margin-left: auto;
                margin-right: auto;
                padding-left: 20px !important;
                padding-right: 20px !important;
                max-width: 100%;
            }

            /* start of navbar.css */
            
            .logo {
                text-decoration: none;
                font-size: 1.5rem;
                font-family: 'Raleway', sans-serif;
                font-weight: 600;
                color: var(--clr-sec);
              }
              .home-branding {
                gap: 8px;
                cursor: pointer;
                display: flex;
                align-items: center;
                flex-direction: row;
              }
              .home-logo {
                width: 45px;
                height: 45px;
                object-fit: cover;
                transition: 0.3s;
              }
              .home-logo:hover {
                transform: rotate(180deg);
              }
              .home-company {
                color: #252525;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 500;
                font-family: 'Poppins', sans-serif !important;
              }
              .home-branding.responsive .home-logo {
                width: 32px;
                height: 32px;
              }
              .home-branding.responsive .home-company {
                font-size: 1.2rem;
              }

            /* end of navbar.css */
            
            
            
            /* start of tag.css */
                        
            .tags-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;
                flex-wrap: wrap;
                /* padding: 1rem 0; */
            }
            .tag-input-container {
                position: relative;
                width: 15rem;
                margin-top: .4rem;
                /* padding: 1.5rem 0; */
            }
            #tag-input {
                /* height: 2.5rem; */
                font-size: 0.9rem;
                padding-right: 2.8rem;
            }
            .tag-input-container button {
                border: none;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            .selected-tag-container {
                display: flex;
                gap: 1rem;
                padding: 1rem 0;
                flex-wrap: wrap;
            }
            .tag {
                font-family: 'Poppins', sans-serif !important;
                background-color: rgb(233, 233, 233);
                padding-left: 1rem;
                border-radius: 30px;
                padding: 0rem 0 0rem 1rem !important;
                font-size: 0.9rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 2.5rem !important;
                padding: 0 1rem !important;
                transition: all 100ms linear;
            }
            .tag:hover{
                text-decoration: underline;
                cursor: pointer;
            }
            .tag-form-container{
                display: flex;
                gap: .5rem;
            }
            /* .tag button {
                display: none;
            } */
            .tag.editing {
                padding: 0rem 0 0rem 1rem !important;
            }
            .tag.editing:hover{
                text-decoration: none;
                cursor: auto;
            }
            /* .tag.editing button {
                display: block;

            } */
            .tag-color-1 {
                background-color: rgb(252, 234, 217) !important;
                color: rgb(105, 68, 34) !important;
            }
            .tag-color-2 {
                background-color: rgb(236, 252, 217) !important;
                color: rgb(52, 82, 16) !important;

            }
            .tag-color-3 {
                background-color: rgb(217, 252, 249) !important;
                color: rgb(16, 75, 70) !important;

            }
            .tag-color-4 {
                background-color: rgb(235, 217, 252) !important;
                color: rgb(88, 43, 129) !important;
            }
            .tag-color-danger {
                background-color: rgba(255, 0, 0, 0.253);
            }
            .noTag-addBtn{
                width: auto;
                min-width: 8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: .5rem;
                border-radius: 20px;
                text-decoration: underline;
                font-size: 0.9rem;
            }
            .edit-btn.noTag-addBtn{
                /* min-width: 13rem */
            }
            .tag-icon {
                font-size: 1.5rem;
                color: rgb(83, 83, 83);
            }

            /* end of tag.css */



            /* start of prompts.css */
            .chat-header{
                margin-bottom: 2rem;
              }
              .chat-name{
                display: flex;
                justify-content: space-between;
              }
              .chat-name span{
                font-size: 1.6rem;
                font-family: 'Poppins', sans-serif !important;
              }
            .chat-main-container{
                padding: 3rem 0;
            }
            .prompt .date{
                font-size: 0.9rem !important
                margin-top: 1rem;
              }
              .question {
                font-family: 'Poppins', sans-serif !important;
                font-size: 1.1rem !important;
                display: flex;
                justify-content: space-between;
                white-space: pre-line;
              }
              .prompt-container .prompt .question svg {
                font-size: 1.8rem;
              }
              .prompt-container .tags {
                display: flex;
                gap: 1rem;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
              }
              .prompt-container .tag {
                padding: 0.5rem 1rem;
              }
              .response {
                font-family: 'Poppins', sans-serif !important;
                background-color: rgb(240, 240, 240);
                padding: 1rem 2rem;
                padding-bottom: 0;
                margin: 1rem 0;
                border-radius: 10px;
                position: relative;
                /* backgroundColor: '#f0f0f0', padding: '1rem 2rem', margin: '1rem 0', borderRadius: '10px', whiteSpace: 'pre-line'  */
                white-space: pre-line;
                padding-top: 3rem;
              }
              .response pre {
                font-family: 'Poppins', sans-serif !important;
                white-space: pre-wrap;
                word-wrap: break-word;
                padding-right: 2.5rem;
                /* padding-top: 2rem; */
              }
              .response p {
                font-size: 0.9rem;
                margin-top: 0.5rem;
              }
              .regenerated-response-header {
                font-weight: 500;
                font-size: 0.9rem;
                margin-top: -1rem !important;
              }
              .response-footer {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                margin-top: 2rem;
                padding: 0.8rem 0;
                border-top: 1px solid rgb(214, 214, 214);
              }
              .response .response-date {
                font-size: 0.9rem;
                color: rgb(65, 65, 65);
                text-align: right;
                padding-top: 2rem;
              }
              .like-icon,
              .dislike-icon {
                fill: rgb(92, 92, 92);
              }
              .like-icon.liked {
                fill: rgba(0, 128, 0, 0.8);
              }
              .dislike-icon.disliked {
                fill: rgba(255, 0, 0, 0.8);
              }
              .copy-btn {
                position: absolute;
                top: 1rem;
                right: 1.2rem;
              }
              .copy-icon {
                font-size: 1.3rem;
                fill: rgb(68, 68, 68);
              }
              .question-header-fixed {
                position: fixed;
                top: calc(var(--nav-height) + 3rem);
                left: 0;
                background-color: white;
                padding: 1rem 0;
                min-height: 4rem;
                width: 100%;
                z-index: 2 !important;
                box-shadow: rgb(100 100 111 / 6%) -1px 20px 29px 0px;
              }
              .question-header-fixed .question span {
                font-size: 18px;
              }

            /* end of prompts.css */
            
            
            
            /* start of promptReply.css */
            
            .add-reply-container {
                margin-top: 4rem;
                margin-bottom: 2rem;
              }
              .reply-header-container {
                display: flex;
                align-items: start;
                gap: 1rem;
              }
              .add-reply-container .header {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: .5rem;
                padding: .5rem 0;
              }
              .add-reply-container .header h6 {
                margin: 0;
                padding: 0;
                font-weight: normal;
              }
              .main-reply-container {
                position: relative;
              }
              .reply-container {
                margin-top: 4rem;
              }
              .reply {
                padding-left: 3vw;
                position: relative;
              }
              .reply::before {
                display:none;
                content: '';
                position: absolute;
                left: 0.5rem;
                top: 1rem;
                width: 1px;
                height: calc(100% - 1rem);
                /* background-color: rgb(202, 202, 202); */
                background-image: linear-gradient(rgb(202, 202, 202) 53%, rgba(255, 255, 255, 0) 0%);
                background-position: right;
                background-size: 1px 10px;
                background-repeat: repeat-y;
              }
              .reply::after {
                display:none;
                content: '';
                position: absolute;
                left: 0;
                top: 1rem;
                width: 1rem;
                height: 1rem;
                border-radius: 100%;
                background-color: var(--clr-main);
              }
              .reply-header {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 1rem;
              }
              .reply-header svg {
                margin-top: 0.3rem;
              }
              .reply-header .prompt {
                font-size: 1.2rem;
                margin: 0;
                padding: 0;
                margin-bottom: 0.2rem;
              }
              .reply-header .date {
                font-size: 0.9rem;
                color: var(--text-main-hover);
                padding: 0;
                margin: 0;
              }
              .textarea-container {
                position: relative !important;
              }
              .textarea-container textarea {
                padding-right: 4rem;
              }
              .textarea-container button {
                position: absolute !important;
                right: 1.3rem;
                top: unset;
                bottom: 15px;
                background-color: var(--clr-main);
                border-radius: 10px;
              }
              .textarea-container button:hover {
                background-color: var(--clr-main-hover);
              }
              .textarea-container button:disabled {
                background-color: transparent;
              }
              .textarea-container button svg {
                fill: var(--clr-sec);
              }
              .textarea-container button:disabled svg {
                opacity: 0.6;
              }
              .regenerate-container {
                position: relative;
                height: fit-content;
              }
              .regenerate-options {
                position: absolute;
                top: 100%;
                right: 1rem;
                z-index: 3;
                display: flex;
                gap: 1rem;
                background-color: white;
                border-radius: 10px;
                box-shadow: rgba(3, 214, 196, 0.3) 0px 0px 0px 3px;
                padding: 1rem;
                width: 28rem;
                max-width: 100%;
              }
              .regenerate-options .link:first-child {
                border-right: 1px solid rgb(172, 172, 172);
                padding-right: 1rem;
              }
              .regenerate-container span.overlay {
                content: '';
                width: 100vw;
                height: 100vh;
                background-color: transparent;
                top: 0;
                left: 0;
                position: fixed;
                z-index: 1;
              }
            
            /* end of promptReply.css */



            /* start of reply.css */

            .profile-pic {
                width: 3rem;
                height: 3rem;
                background-size: cover;
                background-position: center;
                border-radius: 100%;
                display: inline-block;
              }
              .child-reply {
                margin-top: 4rem;
                padding-left: 0;
              }
              .add-reply-btn-container {
                margin-top: 4rem;
              }

            /* end of reply.css */



            /* start of promptNotes.css */
            
            .profile-image {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 100%;
                object-fit: cover;
              }
              .note-container {
                padding: 1.5rem;
                border-radius: 10px;
                background-color: white;
                margin: 1rem 0;
                width: -webkit-fill-available;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: .5rem;
              }
              .note-header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 3rem;
                gap: 9px;
              }
              .note-header p {
                text-align: right !important;
                color: var(--text-main-hover);
                margin: 0;
              }
              .note-container h5 {
                margin: 0;
                font-size: .9rem;
                font-weight: 500;
                text-align: left;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: .5rem;
              }
              .note-container textarea {
                margin-bottom: 1.5rem;
                font-size: 0.9rem;
              }
              .note-container p {
                text-align: left;
                width: 100%;
                font-size: 0.9rem;
              }

            /* end of promptNotes.css */


            .chat-header {
                margin-bottom: 2rem;
            }
            
            .chat-name {
                display: flex;
                justify-content: space-between;
            }
            
            .chat-name span {
                font-size: var(--fs-2);
            }
            
            .chat-info {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #cecece;
                width: 100%;
                height: 1px;
            }
            
            .chat-info span {
                margin: 0;
                min-width: 10vw;
                width: auto;
                text-align: center;
                padding: 0 1.2rem;
                color: #9e9e9e;
                font-size: var(--fs-4);
                font-weight: 300;
                background-color: #fff;
            }
            
            .chat-header-fixed {
                position: fixed;
                top: calc(var(--nav-height) + 3rem);
                left: 0;
                background-color: white;
                padding: 1rem 0;
                min-height: 4rem;
                width: 100%;
                z-index: 2 !important;
                box-shadow: rgb(100 100 111 / 6%) -1px 20px 29px 0px;
            }
            
            .chat-header-fixed .question {
                font-size: 1.1rem !important;
                display: flex;
                justify-content: space-between;
                white-space: pre-line;
            }
            
            .chat-header-fixed .question span {
                font-size: 18px;
                font-size: var(--fs-3);
            }
            
            .copy-btn {
                position: absolute;
                top: 1rem;
                right: 1.2rem;
            }
            
            .copy-icon {
                font-size: 1.3rem;
                fill: rgb(68, 68, 68);
            }
            
            .back-icon {
                font-size: 2rem;
                margin-right: 10px;
            }
            
            
            .textarea-container {
                position: relative !important;
            }
            
            .textarea-container textarea {
                padding-right: 4rem;
            }
            
            .textarea-container button {
                position: absolute !important;
                right: 1.3rem;
                top: unset;
                bottom: 15px;
                background-color: var(--clr-main);
                border-radius: 10px;
            }
            
            .textarea-container button:hover {
                background-color: var(--clr-main-hover);
            }
            
            .textarea-container button:disabled {
                background-color: transparent;
            }
            
            .textarea-container button svg {
                fill: var(--clr-sec);
            }
            
            .textarea-container button:disabled svg {
                opacity: 0.6;
            }
            
            /*  ===================  MESSAGES  ================ */
            .message-container {
                /* min-height: 40vh; */
            }
            
            .messages {
                margin-top: 3rem;
            }
            
            .message {
                /* margin-top: 1.5rem; */
                margin-top: 4rem;
            }
            
            .message-content {
                font-size: var(--fs-4) !important;
                display: flex;
                justify-content: space-between;
                white-space: pre-line;
                margin-bottom: 8px;
            }
            
            .message-header {
                display: flex;
                align-items: start;
                gap: 1rem;
            }
            
            .message-details {
                font-size: var(--fs-5) !important;
                color: var(--text-sec);
            }
            
            
            
            /*  ===================  RESPONSES  ================ */
            .response-body {
                font-family: 'Poppins', sans-serif !important;
                background-color: rgba(240, 240, 240, 0.747);
                padding: 1rem 2rem;
                /* padding-bottom: 2rem; */
                margin: .5rem 0;
                border-radius: 10px;
                white-space: pre-line;
            }
            
            .response-body .response-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            
            .response-body pre {
                font-family: 'Poppins', sans-serif !important;
                white-space: pre-wrap;
                word-wrap: break-word;
                font-size: var(--fs-5);
            }
            
            .response-body span {
                font-size: var(--fs-5);
                font-weight: 500;
            }
            
            .response-body span i {
                display: block;
                margin-top: 1rem;
            }
            
            
            
            /*  ===================  NEW CHAT  ================ */
            
            .regenerate-container {
                position: relative;
                height: fit-content;
                display: flex;
                justify-content: flex-start;
                /* margin-top: -5rem; */
                margin-bottom: 6rem;
            }
            
            .regenerate-options {
                position: absolute;
                top: 100%;
                left: 1rem;
                z-index: 3;
                display: flex;
                gap: 1rem;
                background-color: white;
                border-radius: 10px;
                box-shadow: rgba(3, 214, 196, 0.3) 0px 0px 0px 3px;
                padding: 1rem;
                width: 28rem;
                max-width: 100%;
            }
            
            .regenerate-options .link:first-child {
                border-right: 1px solid rgb(172, 172, 172);
                padding-right: 1rem;
            }
            
            .regenerate-container span.overlay {
                content: '';
                width: 100vw;
                height: 100vh;
                background-color: transparent;
                top: 0;
                left: 0;
                position: fixed;
                z-index: 1;
            }
            
            
            
            
            /*  ===================  NOTES  ================ */
            .notes {
                margin-top: 1rem;
                padding-top: 1rem;
            }
            
            .profile-image {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 100%;
                object-fit: cover;
            }
            
            .note-container {
                padding: 1rem 1.5rem;
                border-radius: 10px;
                background-color: white;
                margin: 1rem 0;
                width: -webkit-fill-available;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
            
            
            .note-header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 3rem;
                gap: 9px;
            }
            
            .note-header p {
                text-align: right !important;
                color: var(--text-main-hover);
                margin: 0;
            }
            
            .note-container h5 {
                margin: 0;
                font-size: var(--fs-5);
                font-weight: 500;
                text-align: left;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: .5rem;
            }
            
            .note-container textarea {
                margin-bottom: 1.5rem;
                font-size: var(--fs-5);
            }
            
            .note-container p {
                text-align: left;
                width: 100%;
                font-size: var(--fs-5) !important;
            }
            
            .note-content {
                padding-left: 3rem;
                margin-top: -5px;
                margin-bottom: 0.7rem;
            }

        </style>
        </head>
        <body>
        ${chatThreadRef.current.outerHTML}
        </body>
        </html>
        `

        ProtectedAxios.post('/users/createChatThreadPdf', { user_id: user.user_id, html })
            .then(res => {
                if (document.getElementById("prompt-header")) {
                    document.getElementById("prompt-header").style.display = 'flex'
                }
                if (document.getElementById("pdf-button")) {
                    document.getElementById("pdf-button").style.display = 'block'
                }
                if (document.getElementById("share-button")) {
                    document.getElementById("share-button").style.display = 'block'
                }
                if (document.querySelectorAll(".remove-tag-button").length) {
                    document.querySelectorAll(".remove-tag-button").forEach(el => {
                        el.style.transform = 'scale(1)'
                    })
                }
                if (document.getElementById("edit-tags-button")) {
                    document.getElementById("edit-tags-button").style.display = 'block'
                }
                if (document.getElementById("add-tags-button")) {
                    document.getElementById("add-tags-button").style.display = 'block'
                }
                if (document.querySelectorAll(".copy-button").length) {
                    document.querySelectorAll(".copy-button").forEach(el => {
                        el.style.display = 'block'
                    })
                }
                if (document.querySelectorAll(".copy-btn").length) {
                    document.querySelectorAll(".copy-btn").forEach(el => {
                        el.style.display = 'block'
                    })
                }
                if (document.querySelectorAll(".add-note-button").length) {
                    document.querySelectorAll(".add-note-button").forEach(el => {
                        el.style.display = 'block'
                    })
                }
                if (document.getElementById("regenerate-response-button")) {
                    document.getElementById("regenerate-response-button").style.display = 'block'
                }
                if (document.getElementById("add-message-form")) {
                    document.getElementById("add-message-form").style.display = 'block'
                }
                if (document.getElementById("add-reply-button")) {
                    document.getElementById("add-reply-button").style.display = 'block'
                }
                if (document.getElementById("add-reply-form")) {
                    document.getElementById("add-reply-form").style.display = 'block'
                }
                if (document.querySelectorAll(".hidden-for-pdf").length) {
                    document.querySelectorAll(".hidden-for-pdf").forEach(el => {
                        el.style.display = 'flex'
                    })
                }
                if (document.querySelectorAll(".note-form").length) {
                    document.querySelectorAll(".note-form").forEach(el => {
                        el.style.display = 'block'
                    })
                }
                if (document.getElementById("tag-form")) {
                    document.getElementById("tag-form").style.display = 'flex'
                }
                if (document.getElementById("scroll-down-button")) {
                    document.getElementById("scroll-down-button").style.display = 'flex'
                }
                if (document.querySelectorAll(".regenerate-options").length) {
                    document.querySelectorAll(".regenerate-options").forEach(el => {
                        el.style.display = 'flex'
                    })
                }
                if (document.querySelectorAll(".pdf-hidden").length) {
                    document.querySelectorAll(".pdf-hidden").forEach(el => {
                        el.style.display = 'block'
                    })
                }
                if (document.querySelectorAll(".response-header button").length) {
                    document.querySelectorAll(".response-header button").forEach(el => {
                        el.style.display = 'flex'
                    })
                }
                document.getElementById("pdf-header").style.display = 'none'
                document.getElementById("chat-options-toggle").style.display = 'block'

                const link = document.createElement('a');
                link.href = res.data;
                link.download = `SageCollab_Chat-${chat_id}_Export_${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setDownloadingPdf(false)
            })
            .catch(err => {
                setDownloadingPdf(false)
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }


    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 300) {
                setShowingChatHeader(true)
            } else {
                setShowingChatHeader(false)
            }


            if (document.getElementById('scroll-down-button')) {
                if (document.body.scrollHeight - window.scrollY < 1200) {
                    document.getElementById('scroll-down-button').style.display = 'none'
                } else {
                    document.getElementById('scroll-down-button').style.display = 'block'
                }
            }
        })
    }, [])

    useEffect(() => {
        fetchChatDetails()
    }, [chat_id])
    const fetchChatDetails = () => {
        setLoading(true)
        setChatDetails('')

        ProtectedAxios.post('/users/getChatDetails', { chat_id, user_id: user.user_id, isViewingWithSharedAccess, isEnterpriseAdmin })
            .then(res => {
                setChatDetails(res.data)
                setLoading(false)

                ProtectedAxios.post("/users/createChatNameAndDescription", { chat_id })
                    .then(res => {
                        if (res.data.chat_name && res.data.chat_description) {
                            setChatDetails(prev => { return { ...prev, chat_name: res.data.chat_name, chat_description: res.data.chat_description } })
                        }
                    })
            })
            .catch(err => {
                setLoading(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    const addTempMessage = (msg) => {
        setMessages(prev => { return [...prev, msg] })
    }
    const addMessage = (newMsg) => {
        setMessages(prev => {
            let updated = [...prev]
            updated.pop()
            return [...updated, newMsg]
        })
    }
    const removeLastTempMessage = () => {
        setMessages(prev => {
            const updated = [...prev]
            updated.pop()
            return updated
        })
    }

    return (
        <>
            {downloadingPdf
                && <div className='overlay-loading'>
                    <div className="spinner-border spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                    <p>Creating PDF</p>
                </div>

            }
            <div className='chat-main-container' ref={chatThreadRef}>
                {/* <button id="pdf-button" onClick={() => downloadPdf()}>to pdf</button> */}
                <div className='container'>
                    <div id='pdf-header' style={{ display: 'none', justifyContent: 'space-between', alignItems: 'center', margin: '-2rem 0 4rem 0' }}>
                        <a target='_blank' style={{ color: '#222', textDecoration: 'none' }} href='https://www.sagecollab.com/'>
                            <div className="home-branding">
                                <img
                                    alt="logo"
                                    src={`${process.env.REACT_APP_BASE_URL}uploads/logo.png`}
                                    className="home-logo"
                                />
                                <span className="home-company">SageCollab</span>
                            </div>
                        </a>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }} id='chat-owner-info'>
                            <div style={{ width: '40px', height: '40px', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${chatDetails.profile_picture_src ? `${chatDetails.profile_picture_src}` : placeholderProfile})` }} />
                            {chatDetails?.username?.substring(0, 20)}{chatDetails?.username?.length > 20 && '...'}
                        </div>
                    </div>
                </div>

                {loading
                    ?
                    <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                        <div className="spinner-border spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>

                    :
                    <>
                        {chatDetails === ''
                            ?
                            <div className='d-flex justify-content-center align-items-center'>
                                <p className='text-danger text-center'>chat not found</p>
                            </div>

                            :
                            <div className=''>
                                <Helmet>
                                    <title>{chatDetails.chat_name} - SageCollab</title>
                                </Helmet>
                                <div className={`${isEnterpriseAdmin ? "mb-5 pb-5" : ""}`}>
                                    {showingChatHeader
                                        &&
                                        <div className='chat-header-fixed' id='prompt-header'>
                                            <div className='question container'>
                                                <div className='d-flex align-items-center gap-2 overflow-hidden'>
                                                    {/* <div className='profile-pic' style={{ backgroundImage: `url(${chatDetails.profile_picture_src ? `${process.env.REACT_APP_BASE_URL}${chatDetails.profile_picture_src}` : placeholderProfile})` }} /> */}
                                                    <div>
                                                        <button type='button' className='button-icon' title='back' onClick={() => navigate(-1)}>
                                                            <BsArrowLeftShort className='font-xl' />
                                                        </button>
                                                    </div>
                                                    <span className="text-truncate pe-3" title={chatDetails.chat_name ? chatDetails.chat_name : chatDetails.message_content.replace(/(\r\n|\r|\n)/g, " ")}>
                                                        {chatDetails.chat_name
                                                            ? <>{chatDetails.chat_name}</>
                                                            : <>{chatDetails.message_content.replace(/(\r\n|\r|\n)/g, " ").substring(0, 50)}{chatDetails.message_content.length > 50 && '...'}</>
                                                        }
                                                    </span>
                                                </div>

                                                <div className='d-flex align-items-center gap-3'>
                                                    {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                        &&
                                                        <ShareChat showAsLink={false} chat_id={chatDetails.chat_id} chatDetails={chatDetails} setChatDetails={setChatDetails} />
                                                    }
                                                    <MyDropdown m0={true} >
                                                        <div className={`dropdown-item`} onClick={() => downloadPdf()}>
                                                            Export to PDF
                                                        </div>
                                                        {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                            &&
                                                            <div className='dropdown-item'>
                                                                <ShareChat showAsLink={true} chat_id={chatDetails.chat_id} chatDetails={chatDetails} setChatDetails={setChatDetails} />
                                                            </div>
                                                        }
                                                        {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                            &&
                                                            <div className='dropdown-item'>
                                                                <ManageChatAccess showAsLink={true} chat_id={chatDetails.chat_id} prompt={chatDetails.message_content} />
                                                            </div>
                                                        }
                                                        {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                            &&
                                                            <div className='dropdown-item'>
                                                                <RenameChat chat_id={chat_id} setRefresh={setRefresh} prompt={chatDetails.message_content} viewingInChat={true} setChatDetails={setChatDetails} chatDetails={chatDetails} />
                                                            </div>
                                                        }
                                                        {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                            &&
                                                            <div className='dropdown-item'>
                                                                <ManageTags setRefreshTags={setRefreshTags} viewAsLisk={true} chat_id={chat_id} isViewingWithSharedAccess={isViewingWithSharedAccess} isEnterpriseAdmin={isEnterpriseAdmin} />
                                                            </div>
                                                        }
                                                        {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                            &&
                                                            <div className='dropdown-item'>
                                                                <DeleteChat chat_id={chat_id} prompt={chatDetails.message_content} chat_name={chatDetails.chat_name} viewingInChat={true} />
                                                            </div>
                                                        }
                                                    </MyDropdown>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className='chat-header container'>
                                        <div className='chat-name'>
                                            <div className='d-flex align-items-center gap-2'>
                                                <div>
                                                    <button type='button' className='button-icon' title='back' onClick={() => navigate(-1)}>
                                                        <BsArrowLeftShort className='font-xl' />
                                                    </button>
                                                </div>
                                                <span title={chatDetails.chat_name ? chatDetails.chat_name : chatDetails.message_content.replace(/(\r\n|\r|\n)/g, " ")}>
                                                    {chatDetails.chat_name
                                                        ? <>{chatDetails.chat_name}</>
                                                        : <>{chatDetails.message_content.replace(/(\r\n|\r|\n)/g, " ").substring(0, 50)}{chatDetails.message_content.length > 50 && '...'}</>
                                                    }
                                                </span>
                                            </div>

                                            <div className='d-flex align-items-center gap-3'>
                                                {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                    &&
                                                    <ShareChat showAsLink={false} chat_id={chatDetails.chat_id} chatDetails={chatDetails} setChatDetails={setChatDetails} />
                                                }
                                                <MyDropdown m0={true} >
                                                    <div className={`dropdown-item`} onClick={() => downloadPdf()}>
                                                        Export to PDF
                                                    </div>
                                                    {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                        &&
                                                        <div className='dropdown-item'>
                                                            <ShareChat showAsLink={true} chat_id={chatDetails.chat_id} chatDetails={chatDetails} setChatDetails={setChatDetails} />
                                                        </div>
                                                    }
                                                    {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                        &&
                                                        <div className='dropdown-item'>
                                                            <ManageChatAccess showAsLink={true} chat_id={chatDetails.chat_id} prompt={chatDetails.message_content} />
                                                        </div>
                                                    }
                                                    {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                        &&
                                                        <div className='dropdown-item'>
                                                            <RenameChat chat_id={chat_id} setRefresh={setRefresh} prompt={chatDetails.message_content} viewingInChat={true} setChatDetails={setChatDetails} chatDetails={chatDetails} />
                                                        </div>
                                                    }
                                                    {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                        &&
                                                        <div className='dropdown-item'>
                                                            <ManageTags setRefreshTags={setRefreshTags} viewAsLisk={true} chat_id={chat_id} isViewingWithSharedAccess={isViewingWithSharedAccess} isEnterpriseAdmin={isEnterpriseAdmin} />
                                                        </div>
                                                    }
                                                    {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                        &&
                                                        <div className='dropdown-item'>
                                                            <DeleteChat chat_id={chat_id} prompt={chatDetails.message_content} chat_name={chatDetails.chat_name} viewingInChat={true} />
                                                        </div>
                                                    }
                                                </MyDropdown>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column mt-4 gap-2'>
                                            <div className='tags p-0'>
                                                <Tags refreshTags={refreshTags} setRefreshTags={setRefreshTags} downloadingPdf={downloadingPdf} chat_id={chat_id} isViewingWithSharedAccess={isViewingWithSharedAccess} isEnterpriseAdmin={isEnterpriseAdmin} />
                                            </div>
                                        </div>

                                    </div>

                                    <Messages
                                        chat_id={chat_id}
                                        chatDetails={chatDetails}
                                        chatDetailsSetter={setChatDetails}
                                        messages={messages}
                                        setMessages={setMessages}
                                        isEnterpriseAdmin={isEnterpriseAdmin}
                                        pageEnded={pageEnded}
                                        setPageEnded={setPageEnded}
                                        tokens={tokens}
                                        updateTokens={updateTokens}
                                        setShowAdModal={setShowAdModal}
                                        setShowMaxInteractionsReachedModal={setShowMaxInteractionsReachedModal}
                                    />

                                    {!isEnterpriseAdmin
                                        &&
                                        <NewMessage
                                            chat_id={chat_id}
                                            tokens={tokens}
                                            updateTokens={updateTokens}
                                            setPageEnded={setPageEnded}
                                            chatDetails={chatDetails}
                                            chatDetailsSetter={setChatDetails}
                                            addTempMessage={addTempMessage}
                                            removeLastTempMessage={removeLastTempMessage}
                                            addMessage={addMessage}
                                        />
                                    }

                                </div>
                                <button type='button' id='scroll-down-button' className='edit-btn scroll-down-button' title='scroll down' onClick={() => window.scrollTo(0, document.body.scrollHeight)}>
                                    <BsArrowDownShort className='edit-icon copy-icon' />
                                </button>
                            </div>
                        }
                    </>
                }
            </div>

            {/* <CustomModal
                show={showMaxInteractionsReachedModel}
                onHide={() => setShowMaxInteractionsReachedModal(false)}
                title={<h3 className='font-m m-0'>Max Limit Reached</h3>}
                content={
                    <div className='my-4'>
                        <h5>Upgrade Your Experience</h5>
                        <p>
                            You have reached maximum number of AI interactions in 24 hours. Please try again later.
                            <br />
                            Upgrade your account to access more AI interactions and premium features.
                        </p>
                    </div>
                }
                buttons={<>
                    <NavLink to="/upgrade" className='button px-4'>Check upgrade options</NavLink>
                </>}
            /> */}
            <MaxAiInteractionsReachedModal
                show={showMaxInteractionsReachedModel}
                onHide={() => setShowMaxInteractionsReachedModal(false)}
            />


            <AdModal
                show={showAdModal}
                onHide={() => setShowAdModal(false)}
            />
        </>
    )
}

export default Chat