import { useState } from 'react';
import { Dialog } from '../../ui/Dialog';
import ProtectedAxios from '../../../api/protectedAxios';
import toast from 'react-hot-toast';

const DeleteTemplateDialog = ({
    selectedTemplate,
    setTemplates,
    user
}) => {
    const [deletingTemplate, setDeletingTemplate] = useState(false)
    const [dialogState, setDialogState] = useState(false)
    const deleteTemplate = async (_template_id) => {
        if (_template_id) {
            setDeletingTemplate(true)
            ProtectedAxios.post('/users/deleteTemplate', { user_id: user.user_id, selected_user_id: user.user_id, template_id: _template_id })
                .then(res => {
                    if (res.data) {
                        setTemplates(prev => {
                            let currentTemplates = [...prev]
                            let updatedTemplates = currentTemplates.filter(template => template.template_id !== _template_id)
                            return updatedTemplates
                        })
                        setDialogState(false)
                        setDeletingTemplate(false)
                    }
                })
                .catch(err => {
                    console.log(err);
                    toast.error("could not delete template at the moment, please try again later.")
                    setDeletingTemplate(false)
                })
        }
    }

    return (
        <>
            <Dialog
                open={dialogState}
                onOpenChange={setDialogState}
                trigger={
                    <div onClick={() => setDialogState(prev => !prev)}>Delete</div>
                }
                title="Delete Template"
                body={
                    <div className='container'>
                        <p className='my-3'>
                            Are you sure you want to delete this template?
                        </p>
                        <p className='m-0'>{selectedTemplate?.name}</p>
                        <p className='text-secondary'>{selectedTemplate?.description}</p>
                    </div>
                }
                footer={
                    <div className='d-flex justify-content-end'>
                        <button className='button button-danger' disabled={deletingTemplate} onClick={() => deleteTemplate(selectedTemplate?.template_id)}>
                            Yes, delete
                            {deletingTemplate
                                &&
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            }
                        </button>
                    </div>
                }
            />
        </>
    )
}

export default DeleteTemplateDialog;