import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import { MdSearch } from 'react-icons/md'
import { toast } from 'react-hot-toast'
import { RiCloseLine } from 'react-icons/ri'
import { AiFillFilter, AiFillTags } from 'react-icons/ai'
import { CgSortAz } from 'react-icons/cg'
import placeholderProfile from "../assets/placeholder-profile.jpg"
import { Modal } from 'react-bootstrap'
import { BsFillChatSquareTextFill } from 'react-icons/bs'
import LoadingSkeleton from '../components/LoadingSkeleton'
import MyDropdown from '../components/MyDropdown'
import CustomModal from '../components/modals/CustomModal'
import { Helmet } from 'react-helmet'
import { FiExternalLink } from 'react-icons/fi'
import HorizontalScroller from '../components/ui/HorizontailScroller'

const SharedChats = React.memo(() => {
  const [user] = useContext(UserContext)

  const [chatList, setChatList] = useState([])

  const [page, setPage] = useState(1)
  const [pageEnded, setPageEnded] = useState(false)

  const [selectedFilterOption, setSelectedFilterOption] = useState(0)
  const [selectedSortOption, setSelectedSortOption] = useState(0)

  const [fetchingChats, setFetchingChats] = useState(false)
  const [fetchingMoreChats, setFetchingMoreChats] = useState(false)

  const [usedTags, setUsedTags] = useState([])
  const [backupUsedTags, setBackupUsedTags] = useState([])
  const [usedTagSearchText, setUsedTagSearchText] = useState("")
  const [viewAllTagsModalState, setViewAllTagsModalState] = useState(false)
  const [selectedTagId, setSelectedTagId] = useState('0')
  const [loadingTags, setLoadingTags] = useState(false)

  const [loadingOwnerList, setLoadingOwnerList] = useState(true)
  const [ownerList, setOwnerList] = useState([])
  const [backupOwnerList, setBackupOwnerList] = useState([])
  const [searchOwnerText, setSearchOwnerText] = useState('')
  const [selectedOwner, setSelectedOwner] = useState('')
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const usedTagsContainerRef = useRef(null)
  const usedTagsRef = useRef(null)
  const [leftScroll, setLeftScroll] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(1)

  const [searchTerm, setSearchTerm] = useState('')

  const navigate = useNavigate()

  const chatContainerEndRef = useRef(null)

  useEffect(() => {
    fetchSharedChats()
  }, [selectedSortOption, selectedTagId, selectedOwner])


  const fetchSharedChats = e => {
    e?.preventDefault()
    setPageEnded(false)
    setPage(1)
    setFetchingChats(true)
    handleClose()

    ProtectedAxios.post('/users/getSharedChats', { user_id: user.user_id, selectedTagId: parseInt(selectedTagId), selectedOwnerId: selectedOwner.owner_id, searchTerm, selectedFilterOption, selectedSortOption, page: 1 })
      .then(res => {
        if (res.data) {
          if (e !== undefined) {
            setSelectedTagId("0")
            fetchAllUsedTagsInSharedChats()
          }
          setChatList(res.data)
          setFetchingChats(false)
        }
      })
      .catch(err => {
        setFetchingChats(false)
        if (err.response.status === 500) {
          toast.error(err.response.data.error)
        } else {
          console.log(err);
        }
      })

  }

  useEffect(() => {
    setSelectedTagId("0")
    fetchAllUsedTagsInSharedChats()
  }, [selectedOwner])
  const fetchAllUsedTagsInSharedChats = () => {
    setLoadingTags(true)
    ProtectedAxios.post('/users/getAllUsedTagsInSharedChats', { user_id: user.user_id, selectedOwnerId: selectedOwner.owner_id, searchTerm, selectedFilterOption })
      .then(res => {
        if (res.data) {
          setUsedTags(res.data)
          setBackupUsedTags(res.data)
          setLoadingTags(false)
        }
      })
      .catch(err => {
        setLoadingTags(false)
        if (err.response.status === 500) {
          toast.error(err.response.data.error)
        } else {
          console.log(err);
        }
      })
  }

  useEffect(() => {
    fetchSharedChatOwnerList()
  }, [])
  const fetchSharedChatOwnerList = () => {
    setLoadingOwnerList(true)
    ProtectedAxios.post('/users/getSharedChatOwnerList', { user_id: user.user_id })
      .then(res => {
        if (res.data) {
          setOwnerList(res.data)
          setBackupOwnerList(res.data)
          setLoadingOwnerList(false)
        }
      })
      .catch(err => {
        setLoadingOwnerList(false)
        if (err.response.status === 500) {
          toast.error(err.response.data.error)
        } else {
          console.log(err);
        }
      })
  }


  const loadMoreChats = () => {
    setFetchingMoreChats(true)
    let updatedPage = page + 1
    setPage(page => page + 1)

    ProtectedAxios.post('/users/getSharedChats', { user_id: user.user_id, selectedTagId: parseInt(selectedTagId), selectedOwnerId: selectedOwner.owner_id, searchTerm, selectedFilterOption, selectedSortOption, page: updatedPage })
      .then(res => {
        if (res.data) {
          if (res.data.length < 4 || res.data.length === 0) {
            setPageEnded(true)
          }
          setChatList(prev => [...prev, ...res.data])
          setFetchingMoreChats(false)
        }
      })
      .catch(err => {
        setFetchingMoreChats(false)
        if (err.response.status === 500) {
          toast.error(err.response.data.error)
        } else {
          console.log(err);
        }
      })
  }


  useEffect(() => {
    filterOwnerList()
  }, [searchOwnerText])
  const filterOwnerList = () => {
    if (searchOwnerText.length > 0) {
      const updatedList = backupOwnerList.filter(user => {
        return (user.owner_username.toLowerCase().includes(searchOwnerText.toLowerCase()) || user.owner_name.toLowerCase().includes(searchOwnerText.toLowerCase()))
      })
      setOwnerList(updatedList)
    } else {
      setOwnerList(backupOwnerList)
    }
  }


  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        loadMoreChats()
      }
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };
    const observer = new IntersectionObserver(handleIntersection, options);
    const loadMoreButton = chatContainerEndRef.current;

    if (loadMoreButton) {
      observer.observe(loadMoreButton);
    }

    return () => {
      if (loadMoreButton) {
        observer.unobserve(loadMoreButton);
      }
    };
  }, [fetchingChats, fetchingMoreChats]);


  function handleChatClick(event, chatId) {
    const documentID = event.id;

    if (event.ctrlKey) {
      window.open(`/shared-chat/${chatId}`, "_blank")
    } else {
      navigate(`/shared-chat/${chatId}`)
    }
  }


  useEffect(() => {
    searchUsedTags()
  }, [usedTagSearchText])
  const searchUsedTags = () => {
    setBackupUsedTags(prev => {
      return usedTags.filter(tag => tag.tag.toLowerCase().includes(usedTagSearchText.toLowerCase()))
    })
  }

  return (
    <div className='container py-5'>
      <Helmet>
        <title>Shared Chats - SageCollab</title>
      </Helmet>

      <div className='flexed-between mb-4 gap-2'>
        <h2 className='text-left w-100 title m-0'>Shared Chat</h2>
        <NavLink to="/start-chat" className='button button-new-chat'>New Chat</NavLink>
      </div>
      <div className='chat-search-container mb-4'>
        <form className='input-with-icon' onSubmit={fetchSharedChats}>
          <input
            className='form-input'
            type="text"
            placeholder="search shared chat"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          {searchTerm.length
            ?
            <button type="submit" className='button-icon edit-btn' title='search'><MdSearch className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
            :
            <></>
          }
        </form>
        <select className='form-select' value={parseInt(selectedFilterOption)} onChange={e => setSelectedFilterOption(parseInt(e.target.value))}>
          <option value={0}>title and content</option>
          <option value={1}>title</option>
          <option value={2}>content</option>
        </select>
      </div>


      {loadingTags
        ?
        <div className='d-flex gap-2 mt-3 px-4'>
          <LoadingSkeleton type="tags" />
          <LoadingSkeleton type="tags" />
          <LoadingSkeleton type="tags" />
        </div>
        :
        <div className="d-flex justify-content-between align-items-center gap-3">
          <HorizontalScroller>
            <div className='used-tags'>
              {usedTags.map((tag, i) => {
                return (
                  <div key={i} id={`used-tag-${tag.tag_id}`} title={`${tag.tag} - ${tag.use_count} chat(s)`} className={`used-tag ${selectedTagId === tag.tag_id && 'active'}`} onClick={() => setSelectedTagId(tag.tag_id)}>{tag.tag} ({tag.use_count})</div>
                )
              })}
            </div>
          </HorizontalScroller>
          {usedTags.length > 6
            &&
            <div>
              <button
                title='view all tags'
                onClick={() => setViewAllTagsModalState(true)}
                className="button-icon"
              >
                <AiFillTags className="font-m" />
              </button>
            </div>
          }
        </div>
      }

      <div className={`my-3 mt-4 d-flex justify-content-between align-items-center`}>
        <div>
          {selectedOwner.owner_name
            &&
            <div className='selected-user'>
              {selectedOwner.owner_username}
              <button type="button" className='button-icon edit-btn' title='clear filter' onClick={() => setSelectedOwner("")}><RiCloseLine className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
            </div>
          }
        </div>

        <div className={`d-flex flex-gap-3 ${fetchingChats ? "disabled" : ""}`}>

          <div className={`toggle-filter-list d-flex align-items-center ${chatList.length === 0 && "disabled"}`}><button className='button button-plain-text p-0' onClick={handleShow}><AiFillFilter />filter by user</button></div>

          <div className={`${chatList.length === 0 && "disabled"}`}>
            <MyDropdown
              disabled={chatList.length === 0}
              position='left'
              dropdownButton={
                <div className='dropdown-link sort-dropdown dropdown'>
                  <button
                    disabled={chatList.length === 0}
                    className='dropdown-toggle'
                  >
                    <CgSortAz />
                    {selectedSortOption === 0
                      ? "Newest"
                      :
                      selectedSortOption === 1
                        ? "Oldest"
                        :
                        selectedSortOption === 2
                          ? "Top Notes"
                          : "New Notes"
                    }
                  </button>
                </div>
              }
              title={
                <div className='text-center mb-4'>
                  <p className='m-0 fw-semibold font-s'>Sort shared chat</p>
                </div>
              }
            >
              <div className={`dropdown-item ${selectedSortOption === 0 ? "active" : ""}`} onClick={() => { setSelectedSortOption(0) }}>Newest</div>
              <div className={`dropdown-item ${selectedSortOption === 1 ? "active" : ""}`} onClick={() => { setSelectedSortOption(1) }}>Oldest</div>
              <div className={`dropdown-item ${selectedSortOption === 2 ? "active" : ""}`} onClick={() => { setSelectedSortOption(2) }}>Top Notes</div>
              <div className={`dropdown-item ${selectedSortOption === 3 ? "active" : ""}`} onClick={() => { setSelectedSortOption(3) }}>New Notes</div>
            </MyDropdown>
          </div>

        </div>
      </div>


      {fetchingChats
        ?
        <div className='prompt-list-container m-0'>
          <LoadingSkeleton type='chat' />
          <LoadingSkeleton type='chat' />
          <LoadingSkeleton type='chat' />
          <LoadingSkeleton type='chat' />
        </div>

        :
        chatList.length === 0
          ?
          <div className='no-chats'>
            <BsFillChatSquareTextFill />
            {selectedTagId !== "0" || searchTerm.length > 0
              ? <h3>No results found</h3>
              :
              <h3>No Chats</h3>
            }
            {selectedTagId === "0" && searchTerm.length === 0
              &&
              <div className='d-flex flex-column'>
                <p>You can view the chats that others have shared with you here</p>
                <NavLink to="/?activeTab=4" className="m-auto color-link text-decoration-none">Explore AI Marketplace <FiExternalLink className='color-link font-s' /></NavLink>
              </div>
            }
          </div>

          :
          <div className='prompt-list-container shared-chats'>
            {chatList.map((prompt, i) => {
              return (
                <div className='prompt-main'>
                  <div key={i} className='prompt' onClick={e => handleChatClick(e, prompt.chat_id)}>
                    <div>
                      <h4
                        title={
                          prompt.chat_name
                            ? prompt.chat_name
                            : prompt.prompt
                        }
                      >
                        {prompt.chat_name
                          ? <>{prompt.chat_name.substring(0, 60)}{prompt.chat_name.length > 60 && '...'}</>
                          : <>{prompt.prompt.substring(0, 60)}{prompt.prompt.length > 60 && '...'}</>
                        }
                      </h4>
                      <p className='tags' title={prompt.tags}>{prompt.tags?.substring(0, 40)}{prompt.tags?.length > 40 && '...'}</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='owner-info'>
                        <div className='profile-pic w-xl h-xl' style={{ backgroundImage: `url(${prompt.profile_picture_src ? `${prompt.profile_picture_src}` : placeholderProfile})` }} />
                        {prompt.username.substring(0, 20)}{prompt.username.length > 20 && '...'}
                      </div>
                      <div>
                        {prompt.model_provider === "OPENAI"
                          ? <img src="https://www.svgrepo.com/show/306500/openai.svg" className='w-s h-s' />
                          : prompt.model_provider === "GEMINI"
                            ? <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" className='w-s h-s' />
                            : ""
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            {!pageEnded
              ?
              fetchingMoreChats
                ?
                <>
                  <LoadingSkeleton type='chat' />
                  <LoadingSkeleton type='chat' />
                  <LoadingSkeleton type='chat' />
                  <LoadingSkeleton type='chat' />
                </>
                :
                <div ref={chatContainerEndRef} />
              : ""
            }
          </div>
      }

      {/* FILTER BY OWNER MODAL */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Filter by users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=''>
            <input type='text' placeholder='search users' value={searchOwnerText} onChange={e => setSearchOwnerText(e.target.value)} />
            <div className='my-2'>
              {ownerList.map((user, i) => {
                return (
                  <div key={i} onClick={() => setSelectedOwner(user)} className={`list-item owner-list-item ${fetchingChats && (selectedOwner.owner_id === user.owner_id ? "fetchingInitialChats" : "disabled")}`}>
                    <div className='profile-pic' style={{ backgroundImage: `url(${user.profile_picture_src ? user.profile_picture_src : placeholderProfile})` }} />
                    <div className='list-item-body'>
                      <p className='name'>{user.owner_name}</p>
                      <p className='username'>{user.owner_username}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button className='button button-danger'>Close</button>
        </Modal.Footer>
      </Modal>



      <CustomModal
        show={viewAllTagsModalState}
        onHide={setViewAllTagsModalState}
        title="All Used Tags"
        content={
          <div>
            <input placeholder='search your tags' value={usedTagSearchText} onChange={e => setUsedTagSearchText(e.target.value)} />

            <div className='my-5 d-flex' >
              <div className='used-tags flex-wrap w-100 overflow-auto gap-3 gap-md-2' style={{ maxHeight: "40dvh" }} >
                {backupUsedTags.length === 0
                  ?
                  <div className='w-100 d-flex flex-column justify-content-center align-items-center gap-2'>
                    <AiFillTags
                      style={{ fontSize: "var(--size-m)" }}
                    />
                    <p className='text-secondary '>No tags found</p>
                  </div>

                  :
                  backupUsedTags.map((tag, i) => {
                    return (
                      <div
                        key={i}
                        title={`${tag.tag} - ${tag.use_count} chat(s)`}
                        className={`used-tag ${selectedTagId === tag.tag_id && 'active'}`}
                        onClick={() => { setViewAllTagsModalState(false); setSelectedTagId(tag.tag_id); document.getElementById(`used-tag-${tag.tag_id}`).scrollIntoView({ offset: 20 }) }}
                      >
                        {tag.tag} ({tag.use_count})
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        }
        showFooter={false}
      />
    </div>
  )
})

export default SharedChats