import { useState } from 'react';
import { Dialog } from '../../ui/Dialog';
import ProtectedAxios from '../../../api/protectedAxios';
import toast from 'react-hot-toast';

const DuplicateTemplateDialog = ({
    selectedTemplate,
    setTemplates
}) => {
    const [duplicatingTemplate, setDuplicatingTemplate] = useState(false)
    const [dialogState, setDialogState] = useState(false)
    const duplicateTemplate = () => {
        if (selectedTemplate?.template_id) {
            setDuplicatingTemplate(true)
            ProtectedAxios.post("/users/template/duplicate", { selectedTemplate })
                .then(res => {
                    setDuplicatingTemplate(false)
                    setTemplates(prev => {
                        const updatedTemplates = [...prev];
                        updatedTemplates.unshift(res.data);
                        return updatedTemplates;
                    })
                    setDialogState(false)
                    toast.success(`Duplicated template: ${selectedTemplate.name}`)
                })
                .catch(err => {
                    setDuplicatingTemplate(false)
                    toast.error("Couldn't duplicate template at the moment")
                })
        }
    }

    return (
        <>
            <Dialog
                open={dialogState}
                onOpenChange={setDialogState}
                trigger={
                    <div onClick={() => setDialogState(prev => !prev)}>Duplicate</div>
                }
                title="Duplicate Template"
                body={
                    <div className='container'>
                        <p className='my-3'>
                            Do you want to duplicate this template?
                        </p>
                        <p className='m-0'>{selectedTemplate?.name}</p>
                        <p className='text-secondary'>{selectedTemplate?.description}</p>
                    </div>
                }
                footer={
                    <div className='d-flex justify-content-end'>
                        <button className='button' disabled={duplicatingTemplate} onClick={duplicateTemplate}>
                            Yes, duplicate
                            {duplicatingTemplate
                                &&
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            }
                        </button>
                    </div>
                }
            />
        </>
    )
}

export default DuplicateTemplateDialog;