import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";

const Dialog = ({
    size = "md",
    trigger,
    title = "Preview",
    closeButton,
    closeButtonFn,
    body,
    footer,
    hideTrigger = false,
    hideHeader = false,
    hideCloseButton = false,
    hideFooter = false,
    modalClass = "",
    headerClass = "",
    titleClass = "",
    bodyClass = "",
    footerClass = "",
    open,
    onOpenChange,
    backdrop = "",
}) => {
    const [isInternalOpen, setIsInternalOpen] = useState(false);

    // Determine if the dialog is controlled or uncontrolled
    const isControlled = open !== undefined;

    const isOpen = isControlled ? open : isInternalOpen;

    const handleOpen = () => {
        if (isControlled) {
            onOpenChange?.(true);
        } else {
            setIsInternalOpen(true);
        }
    };

    const handleClose = () => {
        if (closeButtonFn) {
            closeButtonFn();
        } else {
            if (isControlled) {
                onOpenChange?.(false);
            } else {
                setIsInternalOpen(false);
            }
        }
    };

    return (
        <>
            {!hideTrigger
                &&
                <>
                    {
                        trigger
                            ? React.cloneElement(trigger, {
                                onClick: async (e) => {
                                    // Call the existing onClick handler if it exists
                                    try {
                                        await trigger.props.onClick?.(e);
                                    }
                                    catch (err) {
                                        console.log(err);
                                    }
                                },
                                role: "button",
                                type: "button"
                            })
                            : (
                                <button
                                    type="button"
                                    onClick={handleOpen}
                                >
                                    Open
                                </button>
                            )
                    }
                </>
            }
            <Modal show={isOpen} onHide={handleClose} centered size={size} className={modalClass} {...(backdrop ? { backdrop: backdrop } : {})}>
                {!hideHeader
                    &&
                    <Modal.Header className={headerClass}>
                        <h5 className={titleClass}>
                            {title}
                        </h5>
                        {!hideCloseButton
                            &&
                            <>
                                {
                                    closeButton
                                        ? React.cloneElement(closeButton, {
                                            onClick: handleClose,
                                            role: "button",
                                            type: "button",
                                        })
                                        : (
                                            <button className="button-icon" onClick={handleClose}>
                                                <IoMdClose />
                                            </button>
                                        )
                                }
                            </>
                        }
                    </Modal.Header>
                }
                <Modal.Body className={bodyClass}>
                    {body}
                </Modal.Body>
                {!hideFooter
                    &&
                    <Modal.Footer className={footerClass}>
                        {footer}
                    </Modal.Footer>
                }
            </Modal>
        </>
    );
};

export {
    Dialog
};
